@import 'variables/colors.scss';
@import 'variables/font-size.scss';
@import 'variables/padding.scss';
@import 'variables/border-radius.scss';

.modal-main {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: fade-in($color: transparent, $amount: 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	.modal-content {
		max-width: 520px;
		background-color: var(--color-bg-100-light);
		margin-bottom: 150px;
		border-radius: var(--base-border-radius);
		text-align: center;
		.modal-title {
			margin: 10px;
			font-weight: 700;
			font-size: var(--font-size-4);
			line-height: var(--line-height-4);
		}
		.confirm-modal-footer {
			display: flex;
			justify-content: end;
			gap: 16px;
			padding: 16px 24px 24px;
			border-top: 1px solid #e1e6f5;
			button {
				padding: var(--base-padding);
				font-size: var(--font-size-3);
			}
			.button-cancel {
				background-color: var(--color-bg-cancel-btn);
				border: none;
				border-radius: calc(var(--base-border-radius) * 0.5);
				padding: var(--base-padding) calc(var(--base-padding) * 2);
				display: flex;
				flex-direction: row;
				height: 52px;
				align-items: flex-start;
				gap: 8px;
				font-weight: 600;
				color: var(--color-cancel-btn);
				cursor: pointer;
			}
			.button-ok {
				background-color: var(--color-danger-dark);
				border: none;
				border-radius: calc(var(--base-border-radius) * 0.5);
				padding: var(--base-padding) calc(var(--base-padding) * 2);
				display: flex;
				height: 52px;
				align-items: flex-start;
				gap: 8px;
				color: var(--color-bg-100-light);
				font-weight: 600;
				cursor: pointer;
			}
			.button-sumbit {
				min-width: max-content;
			}
		}
	}
}
.modal-des {
	font-style: normal;
	font-weight: 500;
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	display: flex;
	align-items: center;
	padding: var(--base-padding) calc(var(--base-padding) * 1.5);
}
.confirmation-header {
	height: 160px;
	border-bottom: 1px solid var(--color-bg-80-light);
	padding: var(--base-padding) calc(var(--base-padding) * 1.5);
	background-color: var(--color-bg-modal);
	border-top-left-radius: var(--base-border-radius);
	border-top-right-radius: var(--base-border-radius);
}
.confirmation-image {
	width: 96px;
	height: 96px;
}
@media only screen and (max-width: 600px) and (min-width: 350px) {
	.modal-main {
		position: fixed;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		background: fade-in($color: transparent, $amount: 0.6);
		display: flex;
		align-items: center;
		justify-content: center;
		z-index: 2;
		.modal-content {
			max-width: 350px;
			background-color: var(--color-bg-100-light);
			margin-bottom: 150px;
			border-radius: var(--base-border-radius);
			text-align: center;
		}
	}
}
