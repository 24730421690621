.AssinedUser--container {
	width: 100%;
	height: 100%;
	display: flex;
	gap: 8px;
	flex-direction: column;
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__title {
			color: var(--color-text-light-90);
			font-size: 16px;
			font-weight: 600;
			&--usersCount {
				color: var(--color-text-light-60);
				font-size: 16px;
				font-weight: 500;
			}
		}
		&--inviteUser {
			display: flex;
			padding: 8px 12px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			border: 0px solid var(--color-primary-light);
			color: var(--color-primary-light);
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;
		}
	}
	&__userList {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		flex: 1 0 0;
		align-self: stretch;
		&--data {
			display: flex;
			align-items: center;
			gap: 12px;
			align-self: stretch;
			position: relative;
			.ComplainceAgent--popup {
				display: flex;
				width: 168px;
				padding: 8px 0px;
				flex-direction: column;
				align-items: flex-start;
				border-radius: 4px;
				background-color: var(--color-bg-100-light);
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
				position: absolute;
				top: 30px;
				left: 105px;
				&__edit {
					display: flex;
					width: 168px;
					padding: 8px 16px;
					align-items: center;
					gap: 8px;
					&--icon {
						display: flex;
						width: 20px;
						height: 20px;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
					}
					&--label {
						flex: 1 0 0;
						color: var(--color-text-130-light);
						font-size: 14px;
						font-weight: 500;
						line-height: 20px;
						cursor: pointer;
					}
				}
				&__edit:hover {
					background-color: var(--color-bg-90-light);
					border-radius: 2px;
					transition: all 0.3s;
					cursor: pointer;
				}
				&__delete {
					display: flex;
					width: 168px;
					padding: 8px 16px;
					align-items: center;
					gap: 8px;
					&--icon {
						display: flex;
						width: 20px;
						height: 20px;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
					}
					&--label {
						flex: 1 0 0;
						color: var(--color-text-130-light);
						font-size: 14px;
						font-weight: 500;
						cursor: pointer;
					}
				}
				&__delete:hover {
					background-color: var(--color-bg-90-light);
					border-radius: 2px;
					transition: all 0.3s;
					cursor: pointer;
				}
			}
			&__profile {
				width: 40px;
				height: 40px;
			}
			&__label {
				display: flex;
				justify-content: space-between;
				align-items: center;
				flex: 1 0 0;
				&--name {
					color: var(--color-text-light-90);
					font-size: 16px;
					font-weight: 500;
					word-wrap: break-word;
					word-break: break-word;
				}
				&--account {
					color: var(--color-text-light-60);
					font-size: 14px;
					font-weight: 500;
					word-wrap: break-word;
					word-break: break-word;
				}
			}
			&__icon {
				display: flex;
				width: 24px;
				height: 24px;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				cursor: pointer;
			}
		}
	}
	&__blockTag {
		background: var(--color-dark-red-100);
		color: var(--color-dark-red);
		padding: 2px 10px;
		border-radius: 12px;
		font-size: 12px;
	}
	&__pendingTag {
		background: var(--color-orange-background);
		color: var(--color-orange-text);
		padding: 2px 10px;
		border-radius: 12px;
		font-size: 12px;
		font-weight: 500;
	}
	&__currentTag {
		display: flex;
		gap: 8px;
	}
}
