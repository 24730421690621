.business-transaction-list {
	display: flex;
	flex-direction: column;
	gap: 16px;
	padding: 16px;
	border-radius: 8px;
	background: var(--color-bg-100-light);
	&:hover {
		transition: all 0.2s ease-in-out;
		box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
	}

	.header {
		border-bottom: 1px solid rgba(51, 87, 184, 0.12);
	}

	.business-transaction-list--container {
		width: 100%;
		overflow-y: hidden;
		z-index: 0;

		.react-table-responsive-container {
			margin-left: 0;
			margin-right: 0;
			margin-top: 0;
		}

		.empty-table {
			background-color: #fff;
		}

		.react-table-responsive-wrapper {
			min-height: 0;
		}

		.react-table-responsive__body tr td {
			text-transform: capitalize;
		}

		.react-table-responsive__body tr td:nth-child(4) {
			text-transform: none;
		}

		.status-wrapper {
			display: flex;
			align-items: center;
			gap: 12px;
		}

		.paid,
		.trial,
		.rejected,
		.completed,
		.approved,
		.pending,
		.failed,
		.billing {
			width: 6px;
			height: 6px;
			border-radius: 50%;
		}

		.paid {
			background-color: var(--color-green);
		}

		.trial {
			background-color: var(--color-accent-dark);
		}

		.rejected {
			background-color: var(--color-rejected);
		}

		.completed {
			background-color: var(--color-success);
		}

		.approved {
			background-color: var(--color-success);
		}

		.billing {
			background-color: var(--color-accent-dark);
		}

		.pending {
			background-color: var(--color-accent-dark);
		}

		.failed {
			background-color: var(--color-danger-dark);
		}
	}
}

.loader-container {
	height: 400px;
}
