.table-responsive-actions {
	width: 100%;
	height: 40px;
	display: flex;
	justify-content: space-between;

	&__title {
		font-size: var(--font-size-5);
		color: var(--color-text-90-light);
		font-weight: 500;
	}
	&__btns {
		display: flex;
		align-items: center;
		gap: calc(var(--base-margin) * 1.25);
		margin-bottom: 8px;
		justify-content: flex-end;
	}
}

.react-table-responsive {
	border-collapse: collapse;
	width: 100%;
	&__head {
		background-color: var(--color-table-head-bg);
		position: sticky;
		top: 0;
		z-index: 1;
		tr {
			th {
				padding: var(--base-padding);
				white-space: nowrap;
				overflow: hidden;
				text-overflow: ellipsis;
				color: var(--color-text-80-light);
				font-size: var(--font-size-3);
				font-weight: 600;
				max-width: 128px;
			}
		}
	}

	&__body {
		tr {
			background-color: var(--color-bg-100-light);
			border-bottom: 1px solid var(--color-input-border-light);
			border-left: 1.5px solid transparent;
			&.row__hover:hover {
				background-color: var(--color-hover-light-100);
				transition: 0.3s ease-in all;
				border-left: 1.5px solid var(--color-primary-light);
				cursor: pointer;
			}

			td {
				padding: var(--base-padding);
				color: var(--color-text-90-light);
				position: relative;
				border-right: none;
				font-size: var(--font-size-3);
				font-weight: 500;
				> div {
					overflow: hidden;
					text-overflow: ellipsis;
					word-wrap: break-word;
					word-break: break-word;
				}
				&.btn__text {
					> div {
						display: flex;
						align-items: center;
					}
					.text_cell {
						white-space: nowrap;
						overflow: hidden;
						text-overflow: ellipsis;
					}
				}
				.btn__Onhover {
					display: none;
				}
			}
			&:hover {
				.btn__Onhover {
					display: block;
				}
				.btn__text {
					> div {
						top: 6px;
					}
				}
			}
		}
	}
	&__sort-icon {
		margin-left: calc(var(--base-margin) * 0.5);
		transition: transform 0.5s, border-color 0.5s, -webkit-transform 0.5s;
	}
}
.loader-container {
	width: 100%;
	min-height: 200px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.skeleton-loader-container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.empty-table {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--base-margin);
	&__message {
		text-align: center;
		font-size: var(--font-size-4);
		color: var(--color-text-90-light);
		font-weight: 500;
	}
}
.empty-table-search {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	gap: var(--base-margin);
	height: calc(100vh - 602px);
	&__message {
		text-align: center;
		font-size: var(--font-size-4);
		color: var(--color-text-80-light);
		font-weight: 500;
	}
	&__sub-message {
		text-align: center;
		font-size: var(--font-size-2);
		color: var(--color-text-60-light);
	}
}

.react-table-responsive-wrapper {
	overflow-x: auto;
	width: 100%;
	min-height: 400px;
}

.table-responsive-actions__btns {
	display: flex;
	justify-content: flex-end;
	input::placeholder {
		color: var(--color-text-40-light);
	}
	.input__text-field {
		background-color: var(--color-bg-100-light);
	}
	.ri-search-line {
		color: var(--color-text-40-light);
	}
	.input {
		width: 200px;
		margin-top: -4px;
		.input__value-container {
			.input__group {
				height: 46px;
			}
		}
	}
}
.table__head-data--label {
	display: flex;
	align-items: center;
	justify-content: flex-start;
	cursor: pointer;
	span {
		width: min-content;
		overflow: hidden;
		text-overflow: ellipsis;
	}
	&__true {
		margin-left: 2px;
		transform: rotate(180deg);
		transition: transform 0.1s ease-out;
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
	}
	&__false {
		margin-left: 2px;
		display: flex;
		justify-content: center;
		align-items: center;
		transition: transform 0.1s ease-out;
		font-size: 20px;
	}
}

.react-table-responsive__body tr {
	background-color: var(--color-bg-100-light);
}
