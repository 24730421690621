.CreateNewRoleCard--container {
	display: flex;
	align-items: flex-start;
	align-content: flex-start;
	gap: 20px;
	flex-wrap: wrap;
	width: 100%;

	&__addcard {
		display: flex;
		height: 160px;
		min-width: 300px;
		min-height: 200px;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 8px;
		border: 1.5px dashed var(--color-border-light-90);
		background: var(--color-bg-100-light);
		cursor: pointer;
		width: 48%;
	}
	&__icon {
		display: flex;
		padding: 16px;
		justify-content: center;
		align-items: center;
		gap: 10px;
		border-radius: 64px;
		background: rgba(0, 81, 204, 0.03);
	}
	&__label {
		color: var(--color-text-light-60);
		text-align: center;
		font-size: 14px;
		font-weight: 500;
	}

	&__card {
		display: flex;
		height: 160px;
		min-width: 300px;
		min-height: 200px;
		padding: 12px;
		align-items: flex-start;
		gap: 12px;
		border-radius: 8px;
		border: 1px solid var(--color-label-100-light);
		background: var(--color-bg-100-light);
		cursor: pointer;
		width: 48%;

		&--radio {
			display: flex;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
		}

		&--data {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			height: 100%;
			gap: 10px;
		}
		&--text {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			gap: 8px;
		}
		&--header {
			color: var(--color-text-light-90);
			font-size: 16px;
			font-weight: 600;
		}
		&--desc {
			color: var(--Color-Text-Light-60, #7c8398);
			font-size: 12px;
			font-weight: 400;
		}
	}
	.section-border {
		border: 1.5px solid var(--color-primary-light);
	}
}

@media screen and (max-width: 761px) {
	.CreateNewRoleCard--container {
		display: flex;
		flex-direction: column;
		width: 100%;
		padding-bottom: 10px;
		&__addcard {
			width: 100%;
		}
		&__card {
			width: 100%;
		}
	}
}
