.PermisssionTag--container {
	display: flex;
	flex-direction: row;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 8px;
	flex-wrap: wrap;
	&__label {
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		color: var(--color-pink);
		font-size: 12px;
		font-weight: 500;
		background: linear-gradient(
				0deg,
				rgba(245, 69, 174, 0.16) 0%,
				rgba(245, 69, 174, 0.16) 100%
			),
			#fff;
	}
	&__color {
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		color: var(--color-primary-light);
		font-size: 12px;
		font-weight: 500;
		border-radius: 4px;
		background: rgba(0, 81, 204, 0.12);
		text-transform: capitalize;
		span {
			text-transform: lowercase;
		}
	}

	&__colorPurple {
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		background: rgba(121, 53, 222, 0.12);
		color: var(--color-purple);
		font-size: 12px;
		font-weight: 500;
		text-transform: capitalize;
	}
	&__number {
		display: flex;
		padding: 4px 8px;
		justify-content: center;
		align-items: center;
		gap: 8px;
		border-radius: 4px;
		background: var(--color-hover-light-100, rgba(47, 50, 61, 0.06));
		color: var(--color-black, #000);
		font-size: 12px;
		font-weight: 500;
	}
}
.tooltip-userRole-list {
	text-transform: capitalize;
}
