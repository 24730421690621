.OtpVerify {
	&--formHeader {
		width: 100%;
	}
	&--otp {
		height: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		gap: 16px;
		max-width: 384px;
	}
	&--btn {
		cursor: pointer;
		color: #0051cc;
		font-weight: 500;
	}

	&--otpDetail {
		width: 100%;
		color: #363a45;
		font-size: 24px;
		font-weight: 600;
	}
	&--disabled {
		cursor: not-allowed;
		color: #84a8fa9f;
	}

	&--edit {
		width: 100%;
		color: var(--color-text-130-light);
		font-size: 16px;
		font-weight: 500;
		&__email {
			color: #363a45;
		}
		&__editBtn {
			cursor: pointer;
			color: #0051cc;
			&:hover {
				text-decoration: underline;
			}
		}
		@media only screen and (max-width: 420px) {
			flex-direction: column;
			align-items: center;
		}
	}
	&--container {
		padding: 16px;
	}
	&--line {
		display: flex;
		gap: 16px;
		justify-content: center;
	}

	&--verifyButton {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100%;
		.buttons {
			align-items: center;
			border: none;
			border-radius: 24px;
			color: #fcfcfd;
			display: inline-flex;
			font-size: 16px;
			font-weight: 700;
			height: 48px;
			justify-content: center;
			line-height: 1;
			padding: 0 24px;
			text-align: center;
			transition: all 0.2s;
			width: 274px;
		}
	}
	&--otpInput {
		background-color: transparent;
		border: 1px solid #8d93a5;
		border-radius: 4px;
		color: #1d1e21;
		font-weight: 600;
		height: 50px;
		text-align: center;
		width: 50px;
		font-size: 24px;
	}
	&--resend {
		display: flex;
		gap: 4px;
		font-size: 14px;
		font-weight: 400;
		line-height: 24px;
		@media only screen and (max-width: 320px) {
			flex-direction: column;
			align-items: center;
		}
	}
}
