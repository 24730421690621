.esign-app {
	.react-table-responsive-container {
		height: 100%;
	}

	.react-table-responsive {
		border-collapse: collapse;
		width: 100%;
		cursor: default;

		&__head {
			position: sticky;
			top: 0;
			z-index: 1;
			tr {
				th {
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					font-size: var(--font-size-3);
					font-weight: 600;
					max-width: 128px;
					padding-top: calc(var(--padding) * 0.25);
					padding-bottom: calc(var(--padding) * 0.25);
					div {
						display: flex;
						justify-content: center;
						align-items: center;
					}
				}
			}
		}

		&__body {
			tr {
				border-left: 1.5px solid transparent;
				td {
					padding-top: calc(var(--padding) * 0.5);
					padding-bottom: calc(var(--padding) * 0.5);
					border-right: none;
					font-size: var(--font-size-3);
					font-weight: 500;
					&:nth-child(2) {
						div {
							max-width: 300px;
							white-space: nowrap;
							overflow: hidden;
							text-overflow: ellipsis;
						}
					}
					> div {
						white-space: nowrap;
						text-overflow: ellipsis;
						text-align: center;
						> button {
							margin: auto;
						}
					}
				}
				&:hover {
					.btn__text {
						> div {
							top: 6px;
						}
					}
				}
			}
		}
	}

	.react-table-responsive-wrapper {
		// overflow: auto;
		width: 100%;
		// max-height: 180px;
		// min-height: 120px;
	}

	.table__head-data--label {
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
		span {
			width: min-content;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	.fl-table {
		border-radius: calc(var(--border-radius) * 0.25);
		font-size: var(--font-size-3);
		font-weight: normal;
		border: none;
		border-collapse: collapse;
		width: 100%;
		max-width: 100%;
	}

	.fl-table td,
	.fl-table th {
		text-align: center;
		padding: 0 calc(var(--padding) * 0.5);
	}

	.fl-table thead th {
		color: var(--color-text-80-light);
		background-color: var(--color-bg-70-light);
	}
	.fl-table tr {
		border-bottom: 1px solid var(--color-bg-60-light);
	}

	.fl-table tr:last-child {
		border-bottom: 0px solid var(--color-bg-60-light);
	}
}
