.ComplainceAgent--container {
	display: flex;
	width: 100%;
	padding: 14px 0px 0px 24px;
	flex-direction: column;
	&__back {
		display: flex;
		align-items: center;
		gap: 4px;
		cursor: pointer;
		margin-bottom: 14px;
		width: 250px;
		&--icon {
			display: flex;
			width: 20px;
			height: 20px;
			justify-content: center;
			align-items: center;
		}
		&--label {
			color: var(--color-text-light-60);
			font-size: 14px;
			font-weight: 500;
		}
	}
	&__userAccess {
		display: flex;
		gap: 16px;
		width: 100%;
		padding-right: 24px;
		padding-bottom: 24px;
		flex-wrap: wrap;
		max-height: calc(100vh - 106px);
		overflow: auto;
		@media screen and (min-width: 700px) {
			flex-wrap: nowrap;
		}
		.agent--container {
			display: flex;
			flex-direction: column;
			height: 100%;
			background-color: var(--color-bg-100-light);
			width: 100%;
			border-radius: 8px;
			@media screen and (min-width: 700px) {
				width: 60%;
				height: calc(100vh - 130px);
				overflow-y: auto;
			}
			&__header {
				display: flex;
				padding: 16px;
				flex-direction: column;
				align-items: flex-start;
				gap: 16px;

				&--title {
					display: flex;
					flex-direction: column;
					align-items: flex-start;
					gap: 16px;
					width: 100%;
					&__heading {
						display: flex;
						flex-direction: column;
						align-items: flex-start;
						gap: 16px;

						width: 100%;
						&--text {
							display: flex;
							justify-content: space-between;
							width: 100%;
							&__label {
								color: var(--color-text-light-90);
								font-size: 20px;
								font-weight: 600;
								line-height: 28px;
								word-wrap: break-word;
								word-break: break-word;
								display: flex;
								align-items: center;
							}
							&__btn {
								display: flex;
								align-items: center;
								gap: 8px;
								position: relative;
								height: 40px;
								.ComplainceAgent--popup {
									display: flex;
									width: 168px;
									padding: 8px 0px;
									flex-direction: column;
									align-items: flex-start;
									border-radius: 4px;
									background-color: var(--color-bg-100-light);
									box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
									position: absolute;
									top: 34px;
									right: 24px;
									z-index: 1;
									&__edit {
										display: flex;
										width: 168px;
										padding: 8px 16px;
										align-items: center;
										gap: 8px;
										&--icon {
											display: flex;
											width: 20px;
											height: 20px;
											justify-content: center;
											align-items: center;
											flex-shrink: 0;
										}
										&--label {
											flex: 1 0 0;
											color: var(--color-text-130-light);
											font-size: 14px;
											font-weight: 500;
											line-height: 20px;
											cursor: pointer;
										}
									}
									&__edit:hover {
										background-color: var(--color-bg-90-light);
										border-radius: 2px;
										transition: all 0.3s;
										cursor: pointer;
									}
									&__delete {
										display: flex;
										width: 168px;
										padding: 8px 16px;
										align-items: center;
										gap: 8px;
										&--icon {
											display: flex;
											width: 20px;
											height: 20px;
											justify-content: center;
											align-items: center;
											flex-shrink: 0;
										}
										&--label {
											flex: 1 0 0;
											color: var(--color-text-130-light);
											font-size: 14px;
											font-weight: 500;
											cursor: pointer;
										}
									}
									&__delete:hover {
										background-color: var(--color-bg-90-light);
										border-radius: 2px;
										transition: all 0.3s;
										cursor: pointer;
									}
								}

								&--label {
									display: flex;
									padding: 4px 16px;
									justify-content: center;
									align-items: center;
									gap: 4px;
									border-radius: 4px;
									border: 1px solid var(--color-primary-light);
									color: var(--color-primary-light);
									font-size: 14px;
									font-weight: 500;
									cursor: pointer;
									height: 30px;
								}
								&--icon {
									display: flex;
									width: 28px;
									height: 28px;
									justify-content: center;
									align-items: center;
									gap: 8px;
									font-size: 24px;
									cursor: pointer;
								}
							}
						}
					}
					&__subHeader {
						display: flex;
						align-items: flex-start;
						gap: 8px;
						color: var(--color-text-130-light);
						font-size: 14px;
						font-weight: 400;
						word-wrap: break-word;
						word-break: break-word;
					}
				}
			}
			&__services {
				width: 100%;
			}
			&__permission {
				width: 100%;
				&--table {
					padding: 16px;
				}
			}
		}
		.assinedUser--container {
			display: flex;
			padding: 20px;
			flex-direction: column;
			align-items: flex-start;
			gap: 16px;
			border-radius: 8px;
			background: var(--color-bg-100-light);
			width: 100%;
			@media screen and (min-width: 700px) {
				width: 40%;
				height: calc(100vh - 130px);
				overflow-y: auto;
			}
		}
	}
}
