.NoUserAssined--container {
	width: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	&__wrapper {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		gap: 16px;
		flex: 1 0 0;
		&--text {
			display: flex;
			flex-direction: column;
			align-items: center;
			gap: 4px;
			&__heading {
				color: var(--color-label-dark-200);
				text-align: center;
				font-size: 16px;
				font-weight: 600;
			}
			&__subHeading {
				color: var(--color-text-light-60);
				text-align: center;
				font-size: 12px;
				font-weight: 400;
			}
		}
	}
}
