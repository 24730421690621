.tree-graph-chart-wrapper {
	background-color: 'transparent';
	width: 100%;
	overflow: auto;
}

.onboarding-session-tree-graph {
	min-width: 300px;
	max-width: calc(100vw - 1000px);
	overflow: hidden;
	position: relative;
	transition: 0.3s ease-in all;
	&:hover {
		.tree-graph-show-more {
			display: flex;
		}
	}
}
.session-tree-graph {
	min-width: 300px;
	max-width: calc(100vw - 1200px);
	overflow: hidden;
}
.fund-list-tree-graph {
	min-width: 300px;
	max-width: calc(100vw - 1200px);
	overflow: hidden;
}

.onboarding-invite-tree-graph {
	max-width: 100% !important;
	overflow: auto;
	height: 100% !important;
}

.onboarding-invite-tree-graph-div {
	overflow: auto !important;
	height: 100% !important;
}
.tree-graph-show-more {
	display: none;
	position: absolute;
	top: 0px;
	right: 0px;
	width: 77px;
	height: 100%;
	background: linear-gradient(
		270deg,
		rgba(93, 117, 152, 0.48) 0%,
		rgba(93, 117, 152, 0) 100%
	);
	backdrop-filter: blur(4px);
	color: var(--color-primary-light, #0051cc);
	text-align: center;
	font-size: 12px;
	font-weight: 600;
	line-height: 16px;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	transition: 0.3s ease-in all;
	cursor: pointer;
}

.react-table-responsive__body tr:hover {
	.tree-graph-show-more {
		display: flex;
	}
}
