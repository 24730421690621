.super-admin--container {
	.header {
		border-bottom: 1px solid rgba(51, 87, 184, 0.12);
	}

	.super-admin-container-wrapper {
		display: grid;
		gap: 10px;
		padding: 24px;
	}

	.super-admin-search-container {
		display: flex;
		width: 100%;
		justify-content: flex-end;
		.input {
			width: 250px;
			margin-top: -4px;
			.input__value-container {
				.input__group {
					height: 48px;
				}
			}
			.input__text-field {
				font-size: var(--font-size-4);
				background-color: #fff;
				box-sizing: border-box;
				padding: 12px calc(var(--base-padding) * 0.5);
				margin-left: 14px;
			}
		}

		#dropdown__container {
			margin-top: -4px;
		}

		.css-1t9xogn-control {
			min-width: 220px;
			width: 220px;
			min-height: 48px;
			background-color: #fff;
			transition: none;
		}

		.css-24ka6-control {
			min-height: 48px;
			min-width: 220px;
			width: 150px;
			background-color: #fff;
		}

		.css-qc6sy-singleValue {
			font-size: 14px;
		}
		.calender-btn{
			margin-right: 14px;
			margin-top: -4px;
			border-color: #3c65d60f;
			border: 1px solid var(--color-input-border-light);
		}
	}
}

.super-admin-container {
	width: 100%;
	height: 100%;
	overflow-x: auto;
	.company-brand-logo-wrapper--container {
		display: flex;
		align-items: center;
		gap: 8px;

		.company-brand-logo-wrapper {
			border-radius: 50%;
			display: flex;
			justify-content: flex-start;
			align-items: center;
			background-color: hsla(0, 0%, 0%, 0.09);
			margin-bottom: 2px;
			.company-brand-logo-anchor {
				border: 1px solid transparent;
				transition: 0.2s ease-in-out;
				border-radius: 50%;
			}
			.company-brand-logo-anchor:hover {
				border-radius: 50%;
				border: 1px solid #2251d3;
				i {
					color: #2251d3;
				}
			}
			.brand-logo {
				width: 40px;
				height: 40px;
				border-radius: 50%;
				object-fit: cover;
			}
		}
	}

	.company-brand-logo {
		max-width: 28px;
		max-height: 28px;
		width: 100%;
		height: 100%;
		border-radius: 50%;
		object-fit: contain;
	}

	.default-company-logo {
		color: var(--color-text-60-light);
		font-size: var(--font-size-5);
		object-fit: contain;
		width: 40px;
		height: 40px;
		display: grid;
		place-items: center;
	}

	.react-table-responsive__body tr td {
		text-transform: capitalize;
	}

	.status-wrapper {
		display: flex;
		align-items: center;
		gap: 12px;
		word-break: keep-all;
		white-space: nowrap;
	}

	.paid,
	.trial,
	.rejected,
	.completed,
	.approved,
	.pending,
	.billing,
	.under_review {
		width: 6px;
		min-width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	.requested {
		width: 11px;
		height: 7px;
		border-radius: 50%;
	}

	.paid {
		background-color: var(--color-green);
	}

	.trial {
		background-color: var(--color-accent-dark);
	}

	.rejected {
		background-color: var(--color-rejected);
	}

	.completed {
		background-color: var(--color-success);
	}

	.approved {
		background-color: var(--color-success);
	}

	.billing {
		background-color: var(--color-accent-dark);
	}

	.pending {
		background-color: var(--color-accent-dark);
	}
	.requested {
		background-color: var(--color-purple);
	}
	.under_review {
		background-color: var(--color-orange-light-100);
	}
}

.client-details-modal {
	max-width: 100vw !important;
	.Modal__wrapper {
		width: calc(100vw) !important;
		min-height: calc(100vh - 40px);
	}

	.modal-list-details {
		height: calc(100vh - 250px);
		max-height: calc(100vh - 250px);
		overflow: hidden;
		z-index: 0;
	}

	&__loader-wrapper {
		display: flex;
		flex-direction: column;
		flex: 1;
		justify-content: center;
		margin-top: -72px;
	}

	&__header {
		border-bottom: 1px solid var(--color-white-border);
		padding: calc(var(--base-padding) * 1.5);
		height: 52px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		gap: calc(var(--base-margin) * 0.5);
		width: 100%;
		padding-left: 3.1%;
		padding-right: 8%;
	}

	&__title {
		text-transform: capitalize;
		color: var(--color-black);
		font-weight: 600;
		font-size: var(--font-size-5);
		display: flex;
		align-items: center;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		width: 70%;
	}

	&__sub-title {
		color: var(--color-text-60-light);
		font-weight: 500;
		font-size: var(--font-size-3);
		text-transform: capitalize;
	}

	&__body_wrapper {
		padding: 0 calc(var(--base-padding) * 1.5);
		min-height: 450px;
	}

	&__list-items-wrapper {
		display: flex;
		flex-direction: column;
		gap: var(--base-margin);
	}

	&__list-items {
		display: flex;
		align-items: center;
		width: 100%;
		gap: var(--base-margin);

		.header-list-item {
			font-weight: 600;
			font-size: var(--font-size-4);
			color: var(--color-text-70-light);
			width: calc(100% / 3);

			span {
				font-weight: 500;
				font-size: var(--font-size-3);
				color: var(--color-text-60-light);
			}
		}

		.list-item {
			width: calc(100% / 3);
		}
	}

	&__input-wrapper {
		.input__group {
			height: 36px;
		}
	}

	&__footer-btn {
		border-top: 1px solid var(--color-white-border);
		padding: calc(var(--base-padding) * 0.5);
		display: flex;
		align-items: center;
		gap: calc(var(--base-margin) * 0.5);
		justify-content: flex-end;
	}

	&__btn_wrapper {
		display: flex;
		justify-content: flex-end;
		gap: calc(var(--base-margin));
		padding: 0 10px;
	}
}

.client-details {
	display: flex;
	flex-direction: column;
	gap: calc(var(--base-margin) * 0.5);
	padding: var(--base-padding);
	padding-top: 0;

	&__inner {
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
		min-height: 279.5px;

		&__likedin_url {
			color: #3c65d6;
			word-break: break-word;
			width: 70%;
		}

		&__item {
			display: flex;
			width: 50%;
			align-items: center;
			gap: var(--base-margin);
			padding: var(--base-padding);
		}

		.left-icon_wrapper {
			min-width: 40px;
			min-height: 40px;
			border-radius: 8px;
			display: grid;
			place-items: center;

			i {
				font-size: 20px;
			}
		}

		&__left {
			&--danger {
				background: rgba(245, 69, 133, 0.12);

				color: #f54585;
			}

			&--kyb {
				background: rgba(0, 185, 41, 0.12);
				color: #33b87a;
			}

			&--company {
				background: rgba(69, 139, 245, 0.12);
				color: #3c65d6;
			}

			&--linkedin {
				background: rgba(69, 139, 245, 0.12);
				color: #458bf5;
			}

			&--address {
				background: rgba(108, 112, 122, 0.12);
				color: #8c97b8;
			}

			&--email {
				background: rgba(245, 175, 69, 0.12);
				color: #f5af45;
			}
		}

		&__label {
			font-weight: 500;
			font-size: 12px;
			color: var(--color-text-60-light);
		}

		&__value {
			font-weight: 500;
			font-size: 14px;
			line-height: 20px;
			word-break: break-word;
			color: #2f323d;
		}
	}

	&__btn_wrapper {
		display: flex;
		align-items: center;
		gap: var(--base-margin);
		justify-content: flex-end;
	}

	.linkedin-url-wrapper {
		display: flex;
		color: #2251d3;
		justify-content: space-between;
	}

	.linkedin-verified-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 21%;
	}
}

.v-center-loader {
	display: grid;
	place-items: center;
	margin-top: -72px;
}

.doller-symbol {
	&::before {
		content: '$';
		float: left;
		transform: rotate(-15deg);
	}
}

.row_content_wrapper {
	display: flex;
	flex-direction: column;
	width: 100%;

	&__title {
		color: var(--color-text-80-light);
		font-weight: 500;
		font-size: 14px;
		white-space: nowrap;
		overflow: hidden;
		max-width: 450px;
		text-overflow: ellipsis;
	}

	&__sub_title {
		color: var(--color-text-60-light);
		font-weight: 500;
		font-size: 12px;
		width: 80%;
		overflow: hidden;
		text-overflow: ellipsis;
		text-transform: lowercase;
	}
}

.action-icon-wrapper--container {
	display: flex;
	align-items: center;
	gap: 20px;
	.action-icon-wrapper {
		font-size: 20px;
		cursor: pointer;

		.ri-delete-bißn-line {
			color: red;
		}

		&:hover {
			color: var(--color-primary-light);
			font-weight: 600;
		}
	}
}
.tab-navigation {
	display: flex;
	border-bottom: 1px solid var(--color-white-border);
	cursor: pointer;
	font-size: 14px;
	font-weight: 500;

	.active {
		color: #2251d3;
		border-bottom: solid 2px #2251d3;
		margin-bottom: -1px;
		padding: 0 16px 16px;
	}
	.un-active {
		color: #8d93a5;
		margin-bottom: -1px;
		padding: 0 16px 16px;
		&:hover {
			color: #2251d3;
		}
	}
}

.client-details-modal__footer {
	border-top: 1px solid var(--color-white-border);
	padding-top: calc(var(--base-padding) * 0.8);
	height: 72px;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	margin-top: 8px;
}

.modal-footer-close__button {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
	padding: 16px 24px;
	gap: 4px;
	width: 120px;
	height: 52px;
	background: #ebeef5;
	border-radius: 8px;
	border: none;
	color: #7c8398;
	font-weight: 500;
	font-size: 14px;
	margin-right: 5px;
}

/* width */
::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.approve-modal {
	width: 100%;
	.Modal__wrapper {
		display: flex;
		flex-direction: column;
		padding: 0;
		width: inherit !important;
		min-height: calc(100vh - 40px);
	}

	.client-details-modal__rejected_wrapper {
		padding: 0px 16px 16px 16px;
		display: flex;
		flex-direction: column;

		.textArea_ {
			margin: 5px 0px;
			padding: 0 15px;
			min-height: 580px !important;
		}
	}
}
.total-transaction-table {
	word-break: break-word;
	text-transform: none !important;
	div {
		white-space: normal !important;
	}
}

.start-new-modal,
.use-template-model {
	width: 90%;
	max-width: 100%;

	.FooterNavigate--container {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 12px 24px;
		background-color: #fff;
		border-top: 1px solid var(--color-input-border-light);
		height: 62px;
		bottom: 0;
		width: 100%;
		position: absolute;
		bottom: 0;
		border-radius: 0px 0px 15px 15px;
	}

	.FooterNavigate--right {
		display: flex;
		justify-content: flex-end;
		width: 50%;
		gap: 14px;
	}
	.Modal__wrapper {
		height: 90vh;
		width: 100%;
		padding: unset !important;
		border-radius: 15px;
		position: relative;
		.prepare-container {
			height: 90vh !important;
		}
		.add-recipient-data-container {
			height: calc(90vh - 137px);
			display: block;
			.upload-documents {
				height: unset;
				overflow: visible;
				padding: unset !important;
			}
			.uploaded-files {
				.react-table-responsive-wrapper {
					height: unset;
				}
			}
		}
		.terms-condition {
			height: 90vh;
		}
		.self-sign__wrapper {
			height: 90vh;
			.self-sign__body {
				height: calc(90vh - 160px) !important;
				.menu-items__template-items {
					height: calc(90vh - 240px);
				}
			}
		}
		.left-sidebar {
			.menu-items__template-items {
				height: calc(80vh - 270px);
			}
		}
		.config-items__body {
			height: calc(90vh - 250px) importmemt;
		}
		.editors-dashboard {
			height: 90vh;
		}
		.self-sign__wrapper,
		.editors-dashboard {
			.esign-drawer {
				top: calc(5vh + 79px);
				right: -100%;
			}
			.esign-drawer--open {
				right: 6%;
			}
			.esign-drawer__body {
				height: calc(90vh - 159px);
			}
		}
	}
}

.upload-documents {
	max-width: 800px;
	margin: 0 auto;
	margin-top: calc(var(--margin) * 0.5) 0 0 0 !important;
	width: 100%;
	display: flex;
	flex-direction: column;
	background-color: var(--color-text-white);
	gap: 14px;

	&__header {
		font-style: normal;
		font-weight: 400;
		font-size: var(--font-size-4);
		line-height: var(--line-height-6);
		color: var(--color-text-90-light);
	}
}

.headers {
	height: 62px;
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;
	background-color: var(--color-bg-100-light);
	padding: var(--zero-padding) calc(var(--base-padding) * 1.5);
	border-bottom: 1px solid rgba(51, 87, 184, 0.12);
	border-radius: 15px 15px 0px 0px;

	&__name {
		font-size: var(--font-size-4);
		font-weight: 600;
		color: var(--color-text-100-light);
	}
}

.business-type{
	white-space: nowrap;
}
.businessDetailsModal--body-wapper {
	height: calc(100vh - 125px);
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}