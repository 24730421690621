.business-session-list--container {
	padding: calc(var(--base-padding) * 1.5) 0px;
	width: 100%;
	height: calc(90vh - 174px);
	display: flex;
	flex-direction: column;
	gap: 10px;
	min-height: 480px;

	#dropdown__container {
		display: flex;
		.dropdown {
			width: 200px;
			align-self: flex-end;
		}
	}

	.react-table-responsive__body tr td {
		text-transform: capitalize;
	}

	.status-wrapper {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.paid,
	.trial,
	.rejected,
	.completed,
	.approved,
	.pending,
	.billing {
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	.paid {
		background-color: var(--color-green);
	}

	.trial {
		background-color: var(--color-accent-dark);
	}

	.rejected {
		background-color: var(--color-rejected);
	}

	.completed {
		background-color: var(--color-success);
	}

	.approved {
		background-color: var(--color-success);
	}

	.billing {
		background-color: var(--color-accent-dark);
	}

	.pending {
		background-color: var(--color-accent-dark);
	}
}

.session-user-modal {
	max-width: 100vw !important;
	max-width: 100%;
	top: 0;
	.Modal__wrapper {
		height: calc(100vh - 40px) !important;
		width: calc(100vw) !important;
		min-height: 652px;
		overflow-x: hidden;
		overflow-y: auto;
		padding-top: 0;
	}
	&__onlyRead {
		.action-btn {
			.css-v01j8p-menu {
				display: none;
			}

			.css-f51nph-control,
			.css-ijpopn-control,
			.css-f51nph-control,
			.css-bvp4e2-control,
			.css-6eif92-control,
			.css-1pxm8se-control {
				cursor: not-allowed !important;
				border-color: #cccccc !important;
			}
		}
	}
}

.web-component-xicon .Modal__closeButton {
	position: fixed;
	right: 24px;
	top: 24px;
	z-index: 3;
}

//SESSION LIST

.session-status {
	display: flex;
	align-items: center;
	text-transform: capitalize;
	flex-wrap: wrap;
	.status__clip {
		font-size: var(--font-size-1);
		display: flex;
		align-items: center;
		color: var(--color-bg-100-light);
		line-height: 12px;
		margin-left: -3px;
		margin-bottom: 2px;
		&.statusbg {
			&__pending {
				.inner__status {
					background: var(--color-text-50-light);
				}
				svg > path {
					fill: var(--color-text-50-light);
				}
			}
			&__processing {
				.inner__status {
					background: var(--color-accent-light);
				}
				svg > path {
					fill: var(--color-accent-light);
				}
			}
			&__failed,
			&__rejected {
				.inner__status {
					background: var(--color-rejected);
				}
				svg > path {
					fill: var(--color-rejected);
				}
			}
			&__completed {
				.inner__status {
					background: var(--color-green);
				}
				svg > path {
					fill: var(--color-green);
				}
			}
		}

		&:first-child {
			margin-left: 0px;
			.leftImg {
				display: none;
			}
		}

		.inner__status {
			padding: 4px;
			height: 30px;
			font-size: 12px;
			display: flex;
			font-weight: 500;
			justify-content: center;
			align-items: center;
		}
		svg {
			height: 100%;
		}
	}
}
.view-session-btn--container {
	display: flex;
	align-content: center;
	.review {
		color: #3c65d6;
		font-weight: 600;
		margin-top: 8px;
		cursor: pointer;
		.view-session-btn {
			border: none;
			outline: none;
		}
	}
}

.text-lower-case {
	text-transform: lowercase;
}

.loader-container {
	height: 400px;
}
