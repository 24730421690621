.AddNewRoleModal {
	padding: 12px 24px 24px 24px;
	max-width: unset !important;
}

.AddNewRoleModal--container {
	width: calc(100vw - 100px);
	height: calc(100vh - 220px);
	display: flex;
	flex-direction: column;
	padding: 8px;
	gap: 20px;
	overflow: auto;

	&__header {
		border-bottom: 1px solid var(--color-white-border);
		padding: 0 6px 0 16px;
		display: flex;
		align-items: center;
		margin: 0 -24px;
		min-height: 40px;
	}

	&__title {
		color: #363a45;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
		display: flex;
		align-items: center;
		.AddNewRoleModalheader {
			margin-inline: -24px;
			&--container {
				padding-inline: 24px;
				&__heading {
					line-height: 1.5;
					font-size: 20px;
					font-weight: 600;
				}
				&__info {
					color: var(--color-text-70-light);
					font-size: 12px;
					line-height: 1.5;
					font-weight: 400;
				}
			}
		}
	}

	&__inputRole {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	&__chooseservices {
		display: flex;
		flex-direction: column;
		gap: 8px;
		&__header {
			display: flex;
			flex-direction: row;
			gap: 4px;

			&__title {
				color: var(--color-text-light-90);
				font-size: 16px;
				font-weight: 600;
				word-wrap: break-word;
			}

			&__icon {
				display: flex;
				align-items: center;
				justify-content: center;
				.ri-information-line {
					display: flex;
					width: 20px;
					height: 20px;
					justify-content: center;
					align-items: center;
				}
			}
		}

		&__card {
			display: flex;
			flex-direction: column;
			.chooseservices--container {
				display: flex;
				flex-wrap: wrap;
				gap: 30px;
				&--loader {
					width: 30px;
				}
				&__section {
					position: relative;
					border-radius: 10px;
					border: 2px solid var(--color-label-100-light);
					height: 200px;
					width: 100%;
					position: relative;
					display: flex;
					flex-direction: column;
					align-items: center;
					transition: 0.3 ease-in-out;
					padding: 16px;
					@media screen and (min-width: 600px) {
						width: 47%;
					}
					cursor: pointer;
					.section--container {
						display: flex;
						flex-direction: column;
						justify-content: space-between;
						align-items: flex-start;
						flex: 1 0 0;
						align-self: stretch;

						&__text {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 12px;
							&--heading {
								color: var(--color-bg-80-dark);
								font-size: 16px;
								font-weight: 700;
							}
							&--price {
								display: flex;
								justify-content: center;
								align-items: center;
								&__amount {
									color: var(--color-bg-80-dark);
									font-size: 14px;
									font-weight: 700;
								}
								&__permonth {
									color: var(--color-label-dark-200);
									font-size: 14px;
									font-weight: 400;
									word-wrap: break-word;
								}
							}
						}
						&__textIcon {
							display: flex;
							flex-direction: column;
							align-items: flex-start;
							gap: 12px;
							.account-types {
								display: flex;
								flex-direction: column;
								gap: 4px;
							}
							&--action {
								color: var(--color-text-light-60);
								font-size: 12px;
								font-weight: 600;
								line-height: 20px;
							}
							&--bussinessName {
								display: flex;
								flex-direction: row;
								align-items: flex-start;
								gap: 4px;
								&__icon {
									display: flex;
									width: 16px;
									height: 18px;
									justify-content: center;
									align-items: center;
									&--svg {
										display: flex;
										width: 32px;
										height: 32px;
										justify-content: center;
										align-items: center;
									}
								}
								&__name {
									color: var(--color-text-light-90);
									font-size: 14px;
									font-weight: 500;
								}
							}
						}
					}
					&--radio {
						position: absolute;
						right: 16px;
						top: 12px;
					}
				}
				.section-border {
					border: 2px solid var(--color-primary-light);
				}
			}
		}
	}
	&__permission {
		display: flex;
		flex-direction: column;
		gap: 8px;
		&--header {
			flex-direction: column;
			justify-content: flex-start;
			align-items: flex-start;
			gap: 4px;
			display: flex;
			&__title {
				align-self: stretch;
				color: var(--color-text-light-90);
				font-size: 16px;
				font-weight: 600;
				word-wrap: break-word;
			}
			&__subTitle {
				align-self: stretch;
				color: var(--color-text-130-light);
				font-size: 14px;
				font-weight: 400;
				word-wrap: break-word;
			}
			&__error{
			    background-color: rgba(255, 0, 0, 0.06);
				color: var(--color-text-130-light);
				border: 1px solid var(--color-error);
				border-radius: calc(var(--base-border-radius)* 0.5);
				padding: 6px 10px;
				margin-top: 7px;
				margin-bottom: 5px;
				&--alertIcon{
					vertical-align: middle;
				}
			}
		}

		&--access {
			position: relative;
			display: flex;
			padding: 16px;
			align-items: flex-start;
			gap: 8px;
			align-self: stretch;
			border-radius: 8px;
			background-color: var(--color-bg-100-light);
			border: 2px solid #dfe3ec;

			&__toggle {
				cursor: pointer;
			}

			.All-access--container {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				gap: 8px;
				width: 100%;
				justify-content: space-between;

				&__header {
					flex-direction: column;
					justify-content: flex-start;
					align-items: flex-start;
					gap: 8px;
					display: inline-flex;
					&--title {
						color: black;
						font-size: 14px;
						font-weight: 600;
						word-wrap: break-word;
					}
					&--subTitle {
						color: var(--color-text-130-light);
						font-size: 12px;
						font-weight: 400;
						word-wrap: break-word;
					}
				}
			}
		}
		.toggle-border {
			border: 2px solid var(--color-primary-light);
		}

		&--table {
			display: flex;
			flex-direction: column;
			align-items: flex-start;
			align-self: stretch;
			&__header {
				border-top: 1px solid var(--color-input-border-light);
				border-bottom: 1px solid var(--color-input-border-light);
				display: flex;
				width: 100%;
				padding: 0px 16px;
				align-items: flex-start;
				&--action {
					display: flex;
					padding: 8px 28px;
					flex-direction: column;
					align-items: flex-start;
					gap: 8px;
					width: 50%;
					align-self: stretch;

					&__title {
						display: flex;
						align-items: flex-start;
						gap: 8px;
						color: var(--color-label-dark-200);
						font-size: 14px;
						font-weight: 500;
					}
				}
				&--allowaccess {
					display: flex;
					align-items: flex-start;
					width: 50%;
					&__viewOnly {
						display: flex;
						padding: 8px 0px;
						justify-content: center;
						align-items: center;
						gap: 8px;
						color: var(--color-label-dark-200);
						text-align: center;
						font-size: 14px;
						font-weight: 500;
						width: 40%;
					}
					&__CreateandView {
						display: flex;
						padding: 8px 0px;
						justify-content: center;
						align-items: center;
						gap: 8px;
						color: var(--color-label-dark-200);
						text-align: center;
						font-size: 14px;
						font-weight: 500;
						width: 60%;
					}
				}
			}
			&__body {
				display: flex;
				padding: 4px 0px;
				flex-direction: column;
				align-items: flex-start;
				align-self: stretch;
				.permission-table-checkbox {
					cursor: pointer;
					accent-color: var(--color-primary-light);
				}

				.permission-table-row {
					display: flex;
					padding: 0px 16px;
					align-items: flex-start;
					gap: 16px;
					align-self: stretch;
					margin-bottom: 8px;
					cursor: pointer;
					background: var(
						--color-transparent-light-primary,
						rgba(60, 101, 214, 0.06)
					);
					&__left {
						display: flex;
						align-items: flex-start;
						gap: 8px;
						width: 50%;
						&--dropdown {
							display: flex;
							width: 20px;
							height: 40px;
							justify-content: center;
							align-items: center;
							gap: 8px;
						}
						&--data {
							display: flex;
							padding: 8px 0px;
							align-items: center;
							gap: 8px;
							flex: 1 0 0;
							&__icon {
								display: flex;
								width: 24px;
								height: 24px;
								justify-content: center;
								align-items: center;
							}
							&__label {
								color: var(--color-text-light-90);
								font-size: 14px;
								font-weight: 600;
								line-height: 24px;
							}
							.isopen--label {
								color: var(--color-text-light-40);
								font-size: 14px;
								font-weight: 600;
								line-height: 24px;
							}
						}
					}
					&__right {
						display: flex;
						align-items: flex-start;
						width: 50%;
						&--checkboxView {
							display: flex;
							height: 40px;
							justify-content: center;
							align-items: center;
							gap: 8px;
							width: 40%;
						}
						&--createView {
							display: flex;
							height: 40px;
							justify-content: center;
							align-items: center;
							gap: 8px;
							width: 60%;
						}
					}
					&__dropdownBody {
						display: flex;
						padding: 4px 0px;
						flex-direction: column;
						align-items: flex-start;
						align-self: stretch;

						&--row {
							display: flex;
							padding: 0px 16px;
							align-items: center;
							gap: 16px;
							align-self: stretch;
							width: 100%;
							&__left {
								display: flex;
								padding: 4px 0px;
								align-items: center;
								gap: 8px;
								width: 50%;
								&--dropdown {
									display: flex;
									width: 24px;
									height: 24px;
									justify-content: center;
									align-items: center;
									visibility: hidden;
								}
								&--data {
									color: var(--color-label-dark-200);
									font-size: 14px;
									font-weight: 400;
								}
							}
							&__right {
								display: flex;
								align-items: flex-start;
								align-self: stretch;
								width: 50%;
								&--checkboxView {
									display: flex;
									justify-content: center;
									align-items: center;
									gap: 8px;
									align-self: stretch;
									width: 40%;
								}

								&--createView {
									display: flex;
									justify-content: center;
									align-items: center;
									gap: 8px;
									align-self: stretch;
									width: 60%;
								}
							}
						}
					}
				}
				.disabled-row {
					cursor: not-allowed;
					opacity: 0.5;
				}
			}
		}
	}
	&__footer {
		padding: 16px 24px 0;
		margin: 0 -24px;
		display: flex;
		justify-content: flex-end;
		align-items: center;
		gap: 8px;
		border-top: 1px solid var(--color-label-100-light);
		&--buttonContainer {
			width: 100%;
		}
		.button {
			display: flex;
			padding: 12px 24px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			font-size: 14px;
			font-weight: 500;
			line-height: 24px;
		}
	}
}

.permission-checked {
	color: var(--color-primary-light);
	font-size: 18px;
	font-weight: 600;

	&_full-access {
		color: var(--color-primary-light);
		font-size: 24px;
		font-weight: 600;
	}
}
