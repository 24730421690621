.sidebar-container {
	position: relative;
	border-right: 1px solid rgba(51, 87, 184, 0.12);
	height: 100vh;
	background-color: var(--color-bg-100-light);
	transition: all 0.1s ease-in-out;

	.sidebar-toggle-btn {
		position: absolute;
		top: 83px;
		left: 100%;
		transform: translate(-50%);
		background-color: lightgray;
		height: 32px;
		width: 32px;
		font-size: 22px;
		border-radius: 18px;
		text-align: center;
		cursor: pointer;
		transition: margin-right 2s;
		z-index: 99;
		display: flex;
		justify-content: center;
	}

	&__companyLogo {
		height: 50px;
		width: auto;
		max-width: 100%;
	}

	&__brandLogo {
		width: 100%;
		height: 62px;
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 0px 16px;
		border-bottom: 1px solid rgba(51, 87, 184, 0.12);
		cursor: pointer;

		&__img {
			width: 100%;
			object-fit: contain;
			height: 40px;
		}
	}
}

.sidebar-tabs-container {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: calc(100vh - 84px);
	overflow-y: auto;
	padding-top: 16px;
}

.sidebar {
	&__icon {
		font-size: var(--font-size-5);
	}
	.anything {
		display: flex;
		flex-direction: column;
		gap: calc(var(--base-margin) * 0.25);
	}

	.sidebar__item {
		line-height: 1.7rem;
	}

	&__label {
		color: var(--color-text-50-light);
		padding-left: calc(var(--base-padding) * 2);
	}
	&__item {
		display: flex;
		align-items: center;
		padding: 8px 24px 8px 24px;
		gap: calc(var(--base-margin) * 0.75);
		color: var(--color-text-60-light);
		cursor: pointer;
		&:hover {
			color: var(--color-primary-light);
			transition: color 0.5ms ease-in-out;
			background-color: var(--color-hover-light-100);
		}
		&--active {
			//border-right: solid 4px var(--color-primary-dark);
			background-color: var(--color-hover-light-100);
			color: var(--color-primary-light);
		}

		&--disabled {
			opacity: 0.6;
			cursor: not-allowed;
			&:hover {
				color: var(--color-text-60-light);
				opacity: 0.6;
			}
		}
	}
	&__text {
		font-weight: 600;
		font-size: var(--font-size-3);
	}
}
.active-btn {
	position: absolute;
	width: 4px;
	right: 0px;
	top: 10px;
	bottom: 10px;
	border-radius: calc(var(--base-padding) * 0.25) var(--zero-padding)
		var(--zero-padding) calc(var(--base-padding) * 0.25);

	&--show {
		background-color: var(--color-primary-dark);
	}
}

.theme_button_conatainer {
	display: none;
	// display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: flex-start;
	padding: 8px;
	gap: 14px;
	width: 250px;
	height: 48px;
	background: rgba(0, 0, 0, 0.03);
	border-radius: 8px;
	margin-top: 18px;
}

.activeThemeButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 4px;
	width: 81px;
	height: 32px;
	background: #ffffff;
	box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.08);
	border-radius: 8px;
	border: none;
	font-weight: 600;
	font-size: 12px;
	line-height: 16px;
	color: #515767;
}

.themeButton {
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: 8px 16px;
	gap: 4px;
	width: 81px;
	height: 32px;
	background: transparent;
	border: none;
	font-weight: 500;
	font-size: 12px;
	line-height: 16px;
	color: #7c8398;
}

.theme__icon {
	font-size: 16px;
}

.sidebar-collapse {
	.sidebar-container {
		max-width: 80px;
		min-width: 80px;
		width: 80px;
		transition: width 2s;
		overflow: hidden;
	}
	.sidebar__text {
		display: none;
	}
	.sidebar__item {
		justify-content: center;
	}
	.sidebar__icon {
		font-size: 30px;
	}
}
@media only screen and (max-width: 1080px) {
	.sidebar-container {
		max-width: 80px;
		min-width: 80px;
		width: 80px;
		overflow: hidden;
	}
	.sidebar__text {
		display: none;
	}
	.sidebar__item {
		justify-content: center;
	}
	.sidebar__icon {
		font-size: 30px;
	}
}
