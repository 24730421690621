.mobile-input__container {
	display: flex;
	align-items: flex-start;
	flex-direction: column;
	gap: 4px;
}

.input-form {
	width: 100% !important;
	height: 56px !important;
	display: flex;
	align-items: center;
	background: var(--color-input-background);
	border: 1px solid var(--color-input-border-light);
	padding: calc(var(--padding) * 0.5);
	border-radius: calc(var(--border-radius) * 0.25);
	font-size: var(--font-size-4);
	border-radius: 6px;
	&:focus-within {
		border: 1px solid var(--color-primary-light);
		transition: 0.2s ease-in all;
	}
}

.form-control {
	font-size: var(--font-size-4) !important;
	width: 100% !important;
	margin: 0 !important;
	height: 48px !important;
	border: none !important;
	outline: none !important;
	background: var(--color-input-background) !important;
}

.flag-dropdown {
	border: none !important;
	outline: none !important;
}

.input__error-message {
	color: var(--color-error);
	font-size: var(--font-size-2);
	font-weight: 500;
}

.input__error--border {
	border-color: var(--color-error);
	background: #f705050d !important;
	.form-control {
		background: #ee8d870f !important;
	}
}

.react-tel-input .country-list .search-box {
	width: 85%;
}
.react-tel-input .flag-dropdown {
	background-color: transparent;
}
