.RolesAndPermission {
	display: flex;
	flex-flow: wrap;
	gap: 12px;
	padding: 12px 12px;
	width: 100%;
	overflow: auto;
	max-height: calc(100vh - 62px);

	.overlap-image {
		height: 40px;
		width: 40px;
		border-radius: 40px;
		border: 1px solid transparent;
	}

	.RolesAndPermission--container {
		width: calc((100% - 32px) / 3);
		cursor: pointer;
		@media only screen and (max-width: 900px) {
			width: calc((100% - 16px) / 2);
		}
		@media only screen and (max-width: 650px) {
			width: 100%;
		}

		&__dropOptions {
			margin-top: -8px;
			margin-right: -12px;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 20px;
			height: 100%;
			flex-direction: column;
			gap: 24px;
			border-radius: 6px;
			min-height: 250px;
			max-height: 296px;
			border: 1px solid var(--color-label-100-light);
			background-color: var(--color-bg-100-light);
			&--label {
				display: flex;
				justify-content: space-between;
				gap: 8px;
				&__title {
					color: var(--color-text-light-90);
					font-size: 16px;
					font-weight: 600;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				&__subTitle {
					color: var(--color-text-light-60);
					font-size: 12px;
					font-weight: 400;
					word-wrap: break-word;
					word-break: break-word;
					@supports (-webkit-line-clamp: 3) {
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: initial;
						display: -webkit-box;
						-webkit-line-clamp: 3;
						-webkit-box-orient: vertical;
					}
				}
			}
		}
		&__text {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 90%;
		}
		&__inviteUser {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			&--icon {
				display: flex;
				align-items: center;
				position: relative;
				:nth-child(2) {
					position: absolute;
					left: 22px;
					border: 2px solid var(--color-bg-100-light);
					border-radius: 50%;
				}
				:nth-child(3) {
					position: absolute;
					left: 44px;
					border: 2px solid var(--color-bg-100-light);
					border-radius: 50%;
				}
				:last-child {
					position: absolute;
					left: 72px;
					width: 44px;
					height: 44px;
					border-radius: 50%;
					display: flex;
					justify-content: center;
					align-items: center;
					border: 2px solid var(--color-bg-100-light);
					background: #e9f0fa;
					color: var(--color-black);
					text-align: center;
					font-size: 12px;
					font-weight: 600;
				}
			}
			&--label {
				display: flex;
				padding: 8px 12px;
				justify-content: center;
				align-items: center;
				gap: 4px;
				border-radius: 4px;
				border: 0px solid var(--color-primary-light);
				color: var(--color-primary-light);
				font-size: 14px;
				font-weight: 500;
				cursor: pointer;
			}
		}
		&--popup {
			display: flex;
			width: 168px;
			padding: 8px 0px;
			flex-direction: column;
			align-items: flex-start;
			border-radius: 4px;
			background: var(--color-bg-100-light);
			box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
			position: absolute;
			right: -150px;
			top: 16px;
			z-index: 1;
			&__edit {
				display: flex;
				width: 168px;
				padding: 8px 16px;
				align-items: center;
				gap: 8px;
				&--icon {
					display: flex;
					width: 20px;
					height: 20px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
				}
				&--label {
					color: var(--color-label-dark-100);
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					cursor: pointer;
				}
			}

			&__edit:hover {
				background-color: var(--color-bg-90-light);
				border-radius: 2px;
				transition: all 0.3s;
				cursor: pointer;
			}

			&__delete {
				display: flex;
				width: 168px;
				padding: 8px 16px;
				align-items: center;
				gap: 8px;
				&--icon {
					display: flex;
					width: 20px;
					height: 20px;
					justify-content: center;
					align-items: center;
					flex-shrink: 0;
				}
				&--label {
					color: var(--color-text-130-light);
					font-size: 14px;
					font-weight: 500;
					cursor: pointer;
				}
			}
			&__delete:hover {
				background-color: var(--color-bg-90-light);
				border-radius: 2px;
				transition: all 0.3s;
				cursor: pointer;
			}
		}
	}
	&--overlap-opacity {
		opacity: 0;
	}

	.owner-permission {
		cursor: not-allowed;
	}
}
.height {
	height: calc(100% - 110px) !important;
}
