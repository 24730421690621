.header--container{
    display: grid;
    vertical-align: middle;

    p{
        margin: 0;
        padding: 0;
        font-size: 20px;
        font-weight: 600;
        line-height: 36px;
        letter-spacing: 0em;
        text-align: left;
        text-transform: capitalize;
        color: #1D1E21;

    }
}