.message {
  display: flex;
  gap: calc(var(--base-margin) * 0.5);
  align-items: flex-start;
  font-weight: 500;
  padding: var(--base-padding);
  border-radius: calc(var(--base-border-radius) * 0.5);
  i {
    font-size: var(--font-size-4);
  }
  &--info {
    border: 1px solid var(--color-input-border-focus-light);
    background: rgba(69, 139, 245, 0.06);
    i {
      color: var(--color-input-border-focus-light);
    }
  }
  &--warning {
    border: 1px solid var(--color-orange);
    background: rgba(239, 182, 67, 0.06);
    margin-bottom: 20px;
    i {
      color: var(--color-orange);
    }
  }
}
