.modal-main {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: fade-in($color: transparent, $amount: 0.6);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	.modal-content {
		max-width: 520px;
		background-color: var(--color-bg-100-light);
		margin-bottom: 150px;
		border-radius: calc(var(--base-border-radius) * 0.75);
		text-align: center;
		.modal-title-not {
			color: var(--color-danger-dark);
			margin: 12px;
			font-weight: 700;
			font-size: var(--font-size-4);
			line-height: var(--line-height-4);
		}
		.modal-footer {
			display: flex;
		}
	}
}
.modal-des {
	color: var(--color-label-dark);
	font-weight: 500;
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	display: flex;
	align-items: center;
	padding: calc(var(--base-padding) * 1.5) calc(var(--base-padding) * 2);
}
.notsupported-header {
	height: 160px;
	border-bottom: 1px solid var(--color-white-border);
	padding: var(--base-padding) calc(var(--base-padding) * 0.5);
	background-color: var(--color-bg-modal);
	border-top-left-radius: calc(var(--base-padding) * 0.75);
	border-top-right-radius: calc(var(--base-padding) * 0.75);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
.confirmation-image {
	width: 96px;
	height: 96px;
}
