.revenue-container {
	width: 100%;
	height: 100%;
	overflow-x: auto;

	.revevue-container-wrapper {
		padding: 24px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		max-width: calc(100vw - 80px);
		max-height: calc(100vh - 60px);
		overflow-y: auto;
	}

	.status-wrapper {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.paid,
	.trial,
	.rejected,
	.completed,
	.approved,
	.pending,
	.failed,
	.billing {
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	.paid {
		background-color: var(--color-green);
	}

	.pending {
		background-color: var(--color-accent-dark);
	}

	.failed {
		background-color: var(--color-danger-dark);
	}

	.revenue-details-table {
		background: var(--color-bg-100-light);
		border-radius: 8px;
		padding: 16px;
		&:hover {
			transition: all 0.2s ease-in-out;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		}
	}

	.react-table-responsive-container {
		.react-table-responsive__body tr:hover {
			cursor: pointer;
			background: var(--color-bg-200-light);
			&:hover {
				background-color: var(--color-hover-light-100);
				transition: 0.1s ease-in all;
				border-left: 1.5px solid var(--color-primary-light);
			}
		}

		tr td:nth-child(2) div {
			top: 10px;
		}

		tr td:nth-child(7) {
			text-transform: capitalize;
		}
	}
}

.revenue-pagination {
	position: relative;
	justify-content: flex-end;
	margin-top: 10px;
}

.power_BI__parent-container {
	display: grid;
	place-items: center; /* Center both horizontally and vertically */
	height: 100vh; /* Full viewport height */
	width: 100%; /* Full width */
}
