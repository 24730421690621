.audit-skelton--container {
	display: flex;
	flex-direction: column;
	gap: 24px;
	padding: 24px;
	overflow-y: auto;
	height: 700px;
}

.skeleton {
	display: flex;
	align-items: center;
	width: 100%;
	padding: 16px;
	border-radius: 8px;
	border: none;
	outline: none;
	background-color: var(--color-bg-100-light);
	box-shadow: 0px 0px 8px 0px #0000003d;
	gap: 16px;
}

.skeleton-text {
	display: grid;
	gap: 10px;
	width: 100%;
}

.skeleton-loader-light {
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#adb0b8;
	background-repeat: repeat-y;
	background-size: 20px 50px;
	background-position: 0 0;
	animation: shine 2s infinite;
}

.skeleton-img {
	width: 44px;
	height: 44px;
	border-radius: 32px;
	@extend .skeleton-loader-light;
}

.skeleton-description {
	display: block;
	width: 100%;
	height: 12px;
	@extend .skeleton-loader-light;
	border-radius: 8px;
}

.skeleton-time {
	display: block;
	width: 90px;
	height: 10px;
	@extend .skeleton-loader-light;
	border-radius: 8px;
}

@keyframes shine {
	to {
		background-position: 100% 0, /* move highlight to right */ 0 0;
	}
	100% {
		background-position: 100% 0;
	}
}
