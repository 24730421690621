.filter-btn-wrapper {
	height: 48px;
	display: flex;
	margin-right: 14px;
	margin-top: -4px;
	background-color: white;
	border-radius: calc(var(--base-border-radius)* 0.5);
	border: 1px solid var(--color-input-border-light);
	&__popup-wrapper{
		position: relative;
	}
	&__inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		min-width: 105px;
		&__label {
			color: var(--color-label-dark-100);
			font-weight: 500;
		}
		i {
			margin-left: calc(var(--base-margin) * 0) !important;
		}
	}
}

.Table-header--filter-bg-active {
	background-color: var(--color-primary-light);
	transition: all 0.5s ease-in-out;
	.Table-header--selected {
		color: var(--color-bg-100-light);
	}
}

.Table-header--container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: fit-content;
	font-weight: 500;
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	cursor: pointer;
	color: var(--color-text-90-dark);
	border-radius: calc(var(--base-border-radius) * 0.5);
	padding: 8px 16px;
	height: 48px;

	i {
		font-size: 24px;
		color: var(--color-text-40-light);
		margin-left: 12px;
	}
}

.Table-header--container:hover {
	border-color: var(--color-primary-light);
	transition: all 0.3s ease-in-out;
	i {
		color: var(--color-primary-light);
	}
}

.Table-header--filter-active {
	border-color: var(--color-primary-light);
	transition: all 0.3s ease-in-out;
}

.Table-header--selected {
	font-weight: 500;
	color: var(--color-bg-80-dark);
}

.Table-header--filter-icon-active {
	color: var(--color-bg-100-light) !important;
}

.Table-header--filter-bg-active {
	background-color: var(--color-primary-light);
	transition: all 0.5s ease-in-out;
	.Table-header--selected {
		color: var(--color-bg-100-light);
	}
}

.filter-btn-wrapper--filter-disabled {
	opacity: 0.7;
	cursor: not-allowed;
	&:hover {
		border: 1px solid var(--color-input-border-light) !important;
		i {
			color: var(--color-text-40-light) !important;
		}
	}
}
@media only screen and (max-width: 600px) {
	.Table-header--container {
		width: 100%;
		display: flex;
		justify-content: space-between;
	}
}

.filter-popup-wrapper {
	gap: calc(var(--base-margin) * 0.25);
	width: -moz-fit-content;
	width: fit-content;
	background-color: var(--color-bg-100-light);
	box-shadow: 0px 4px 60px rgb(0 0 0 / 10%);
	position: absolute;
	right: 0;
	top: 50px;
	z-index: 4;
	min-width: 336px;
	min-height: 100px;
	border-radius: 8px;
}

.reset-filter-all {
	min-width: 54px;
	padding: 4px 8px;
	border-radius: 4px;
	font-size: 12px;
	background-color: white;
	color: var(--color-text-80-light);
	font-weight: 700;
	cursor: pointer;
	border: 1px solid var(--color-text-50-light);
}

.headingrow {
	display: flex;
	justify-content: space-between;
	border-bottom: 1px solid rgba(215, 223, 245, 1);
	padding: 16px;
}

input[type='checkbox'].popup-menu-item__checkbox {
	accent-color: var(--color-primary-light);
}

.filter-popup {
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		font-weight: 500;
		font-size: 14px;
		line-height: 20px;
		padding: 16px;
		// border-bottom: 1px solid rgba(215, 223, 245, 1);
		cursor: pointer;
		&__selected-count {
			font-size: 12px;
		}

		&__label {
			color: var(--color-text-80-light);
		}
		&__reset {
			color: var(--color-text-120-light);
			cursor: pointer;
			&:hover {
				color: var(--color-primary-light);
			}
		}
	}
	&__onboardingflow-btn,
	&__active-status-btn {
		width: 304px;
		border: 1px solid var(--color-input-border-light);
		border-radius: 6px;
		background: var(--color-input-background);
		font-size: 16px;
		line-height: 20px;
		margin-bottom: 10px;
		color: var(--color-text-120-light);
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 10px;
		cursor: pointer;
	}
	&__active-status-btn:hover {
		border-color: var(--color-primary-light);
		transition: all 0.5s;
	}
	&__closed {
		transform: rotate(0deg);
		transition: transform 0.25s;
		font-size: 30px;
	}
	&__opened {
		transform: rotate(-180deg);
		transition: transform 0.25s;
		font-size: 30px;
	}
	&__header:last-child {
		border-bottom: none;
	}
}

.bottom-touch {
	border-bottom: 1px solid rgba(215, 223, 245, 1);
}

.filter-popup__active-status {
	border-color: var(--color-primary-light);
	transition: all 0.5s;
}
.padding-wrapper {
	max-height: 150px;
	overflow-y: overlay;
}

.filter-popup__onboardingflow-wrapper,
.filter-popup__active-status-wrapper {
	display: flex;
	flex-direction: column;
}

.filter-popup__onboardingflow-btn:hover {
	border-color: var(--color-primary-light);
}
@media only screen and (max-width: 600px) and (min-width: 350px) {
	.filter-popup-wrapper{
		min-width: 327px;
		min-height: 200px;
		left: -18px;
		width: 100px;
		width: -moz-fit-content;
	}
	.Filter-title {
		width: 100%;
		max-width: 334px;
		padding: 46px;
		text-align: center;
	}
}
@media only screen and (max-width: 600px) and (min-width: 350px) {
	.filter-popup-wrapper {
		left: -8px;
		width: unset;
		min-width: 279px;
	}
	
	.filter-popup__header,
	.filter-popup {
		&__header:last-child {
			border-bottom: none;
		}
		display: flex;
		align-items: center;
		justify-content: space-between;
		overflow: hidden;
		gap: 130px;
		padding-right: 50px;
	}
	.filter-popup__onboardingflow-btn,
	.filter-popup__active-status-btn {
		width: 255px;
	}
}

.popup-menu-item {
	position: relative;
	display: flex;
	align-items: center;
	justify-content: space-between;
	gap: 4px;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-text-110-light);
	padding: 8px 16px;
	cursor: pointer;

	&__checkbox {
		width: 20px;
		height: 20px;
		cursor: pointer;
	}
	&__left {
		display: flex;
		align-items: center;
		gap: 4px;
		flex-grow: 1;
	}
	&__right {
		display: flex;
		align-items: center;
		gap: 4px;
		&__icon {
			font-size: 20px;
			padding: 0 4px;
			cursor: pointer;
			&__closed {
				transform: rotate(0deg);
				transition: transform 0.25s;
			}
			&__opened {
				transform: rotate(-90deg);
				transition: transform 0.25s;
			}
		}
	}
}

.popup-menu-item:hover {
	background-color: var(--color-bg-90-light);
	border-radius: 2px;
	transition: all 0.3s;
	.Popup--label-txt {
		color: var(--color-text-100-light);
	}
}



.Popup--label-txt {
	font-weight: 400;
	margin-left: 8px;
	width: 180px;
	white-space: nowrap;
	overflow: hidden !important;
	text-overflow: ellipsis;
	flex-grow: 1;
}

.RenderListItem--empty {
	color: var(--color-text-110-light);
	text-align: center;
}
