.browse-file-sign-doc {
	margin-top: 20px;
	&__loader-text {
		font-weight: 500;
		font-size: var(--font-size-4);
		color: var(--color-text-90-light);
	}
	.browse-file {
		&__label-box {
			width: 100%;
			margin: calc(var(--margin) * 0.5) 0;
			min-height: 220px;
			background-color: var(--color-bg-90-light);
			border: 1px dashed var(--color-border-90-light);
			border-radius: calc(var(--border-radius) * 0.5);
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			cursor: pointer;
			position: relative;
			&:hover {
				border: 1px solid var(--color-esign-primary-light);
				transition: 0.2s ease-in all;
			}
		}
		&__logo {
			font-size: 68px;
			color: var(--color-esign-primary-light);
		}
		&__label-text {
			display: flex;
			gap: calc(var(--margin) * 0.25);
			align-items: center;
		}
		&__light-text {
			font-size: var(--font-size-3);
			color: var(--color-text-80-light);
			font-weight: 500;
		}
		&__click-label {
			color: var(--color-esign-primary-light);
		}

		&__description {
			color: var(--color-text-50-light);
			font-size: var(--font-size-2);
			font-weight: 400;
		}

		&__input {
			width: 100%;
			height: 100%;
			position: absolute;
			background-color: transparent;
			opacity: 0;
			cursor: pointer;
		}
	}
}

.uploadedFiles__name {
	cursor: pointer;
}

.upload-doc-table {
	&__action-wrapper {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: 8px;
	}

	&__delete-button {
		font-size: 16px;
		padding: 4px 8px;
		border-radius: 32px;
		background: none;
	}
}

.container {
	position: relative;
	width: 50%;
	margin: 280px auto;
	padding: 20px 40px;
	border-radius: 4px;
	box-sizing: border-box;
	background: #fff;
	box-shadow: 0 10px 20px rgba(0, 0, 0, 0.5);
}

.loade-files {
	display: flex;
	justify-content: center;
	margin-top: 50px;
	flex-direction: row;
	align-items: center;
	gap: 4px;

	&__image-doc {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 40px;
		height: 40px;
		padding: 8px 0px 0px 0px;
		gap: 8px;
		border-radius: 32px;
		background: #f1f1f1;
	}

	&__upload {
		font-weight: 500;
		size: var(--font-size-2);
		color: var(--color-text-60-light);
	}
}

.container-loading {
	display: flex;
	width: 100%;
	border-radius: 4px;
	box-sizing: border-box;
	background: #fff;
}

.Loading {
	position: relative;
	display: inline-block;
	width: 100%;
	height: 10px;
	background: #f1f1f1;
	border-radius: 4px;
	overflow: hidden;
}

.Loading:after {
	content: '';
	position: absolute;
	left: 0;
	width: 0;
	height: 100%;
	border-radius: 4px;
	animation: load 5s infinite;
}

@keyframes load {
	0% {
		width: 0;
		background: var(--color-blue);
	}

	25% {
		width: 40%;
		background: var(--color-blue);
	}

	50% {
		width: 60%;
		background: var(--color-blue);
	}

	75% {
		width: 75%;
		background: var(--color-blue);
	}

	100% {
		width: 100%;
		background: var(--color-blue);
	}
}

@keyframes pulse {
	0% {
		background: var(--color-blue);
	}

	25% {
		background: var(--color-blue);
	}

	50% {
		background: var(--color-blue);
	}

	75% {
		background: var(--color-blue);
	}

	100% {
		background: var(--color-blue);
	}
}
