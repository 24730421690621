.DropOption {
	width: 24px;
	height: 24px;
	font-size: 20px;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	&:hover {
		color: var(--color-primary-light);
		font-weight: 600;
	}
}
.DropOption__list {
	position: absolute;
	top: 65%;
	right: 20%;
	padding: 8px 0;
	border-radius: 4px;
	background-color: #fff;
	box-shadow: 0px 0px 4px 0px #0000001f;
	z-index: 1;
	display: flex;
	flex-direction: column;
	gap: 4px;
}
.DropOption__listItem {
	font-size: var(--font-size-3);
	font-weight: 500;
	line-height: 20px;
	letter-spacing: 0px;
	text-align: left;
	padding: 12px 16px;
	white-space: nowrap;
	&:hover {
		background: #f4f5f5;
	}
}
