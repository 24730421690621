.signin-wrapper {
	width: 100%;
	height: 100%;
	position: absolute;
	display: grid;
	place-items: center;
	inset: 0;
	&__inner {
		align-items: center;
		display: flex;
		flex-direction: column;
		gap: 40px;
		max-width: 480px;
		width: 100%;
		&__form {
			background: var(--color-bg-100-light);
			border-radius: 8px;
			display: flex;
			flex-direction: column;
			gap: 24px;
			justify-content: center;
			padding: 24px 16px;
			width: 100%;

			&__title {
				color: #363a45;
				font-size: 24px;
				font-weight: 600;
				line-height: 32px;
			}

			&__subtitle {
				color: var(--color-text-130-light);
				font-size: 14px;
				font-weight: 400;
				line-height: 20px;
			}
			&__OR {
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 8px;
				font-size: 14px;
				font-weight: 500;
				color: var(--color-text-130-light);

				hr {
					width: 100%;
					border: 0.5px solid #d9d9d9;
					opacity: 1;
					margin: 0;
				}
			}

			&__google {
				border: 1px solid #0000001f;
				padding: 12px 24px;
				border-radius: 8px;
				display: flex;
				justify-content: center;
				align-items: center;
				gap: 10px;
				font-size: 16px;
				font-weight: 500;
				line-height: 24px;
				color: #515767;
				cursor: pointer;
				&:hover {
					border: 1px solid var(--color-primary-light);
				}
				&_image {
					height: 26px;
					width: 26px;
					object-fit: none;
					object-position: -37px -11px;
				}
			}
		}
	}
}

.company-logo {
	position: fixed;
	z-index: 2;
	top: 16px;
	left: 24px;
	&_image {
		width: 164px;
	}
}
