.pagination__container {
	position: absolute;
	right: 22px;
	bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;

	.loader-text {
		font-weight: 400;
		margin: 2px 5px 0 5px;
		display: fle;
		flex-direction: row;
		flex-wrap: nowrap;
		width: auto;
		div {
			display: inline-block;
			width: auto;
		}
	}

	button {
		min-width: 50px;
		padding: 5px 0;
		margin: 0px 5px;
		background-color: #fff;
		box-shadow: 1px 1px 4px 1px #e5e0e0;
		font-size: xx-small;
		i {
			font-size: 24px;
			margin-left: -10px;
		}
	}
}
