.revenue-details-skeleton-container{
    display: flex;
    flex-flow: row wrap;
    flex: 1;
    gap: 16px;
    
    .skeleton-loader-light{
        background: linear-gradient(
                to right,
                rgba(255, 255, 255, 0),
                rgba(255, 255, 255, 0.2) 50%,
                rgba(255, 255, 255, 0) 80%
            ),
            #adb0b8;
        background-repeat: repeat-y;
        background-size: 20px 50px;
        background-position: 0 0;
        animation: shine 2s infinite;
    }

    .revenue-details-overview{
        background-color: white;
        padding: 16px;
        border-radius: 8px;
        box-sizing: border-box;
        border: solid 1px #DFE3EC;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 12px;
       
        .skeleton-loader-icon{
            height: 60px;
            width: 60px;
            border-radius: 35px;
            padding: 8px;
            gap: 8px;
            @extend .skeleton-loader-light;

        }


        .revenue-details-overview-text{
            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            align-items: flex-start;

            .revenue-details-profile-header{
                display: flex;
                flex-direction: column;
                align-items: flex-start;
            }

            .revenue-details-profit{
                height: 12px;
                width: 150px;
                border-radius: 8px;
                @extend .skeleton-loader-light;
            }

            .revenue-details-title{
                height: 8px;
                width: 100px;
                border-radius: 8px;
                @extend .skeleton-loader-light;
                margin-top: 6px;
                align-self: flex-start;
            }

          
        }

    }
}