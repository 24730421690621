.date-range-picker-overlay {
  z-index: 3;
  position: absolute;
  top: 0px;
  right: 0px;
  left: 0px;
  bottom: 0px;
}

.date-range-picker {
  display: flex;
  gap: calc(var(--base-margin) * 0.25);
  // border: 1px solid var(--color-input-border-light);
  width: fit-content;
  padding: calc(var(--base-padding) * 0.5);
  border-radius: calc(var(--base-border-radius) * 0.75);
  background-color: var(--color-bg-100-light);
  box-shadow: 0px 4px 60px rgb(0 0 0 / 10%);
  position: absolute;
  top: 0px;
  right: 0px;
  z-index: 4;
  min-width: 450px;
}

.overlay-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  gap: calc(var(--base-margin) * 0.25);
  padding-bottom: calc(var(--base-padding) * 0.5);
  padding-top: calc(var(--base-padding) * 0.75);
}

.rdrCalendarWrapper {
  background-color: var(--color-transparent);
}

.rdrInputRanges {
  display: none;
}

.rdrDefinedRangesWrapper {
  width: 130px !important;
  border: none !important;
  background: unset !important;
}

.rdrMonthAndYearWrapper {
  background-color: var(--color-bg-blue-dark);
  color: var(--color-text-100-dark);
}

.rdrWeekDay {
  color: var(--color-golden) !important;
}

.rdrMonths {
  background-color: var(--color-bg-blue-dark);
}

.rdrDay .rdrDayNumber span {
  color: var(--color-text-100-dark);
}

// .overlay .rdrStaticRanges .rdrStaticRange {
//   display: flex;
//   justify-content: center;
//   border: none;
//   color: var(--color-test-100-dark);
//   background: var(--color-bg-blue-dark);
//   height: 100%;
//   border-bottom: 1px solid var(--color-input-border-dark);

//   &:last-child {
//     border: none;
//   }
// }

// .overlay .rdrStaticRanges .rdrStaticRange .rdrStaticRangeLabel {
//   width: 100%;
//   text-align: center;
//   transition: background-color 120ms ease-out, color 120ms ease-out;
//   color: var(--color-text-100-dark);
//   font-weight: 600;

//   &:hover {
//     background: hsla(0, 0%, 100%, 0.03);
//     color: var(--color-primary-dark);
//   }
// }

.DefinedRange--container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.rdrDay .rdrDayNumber span {
  color: var(--color-text-100-dark);
}
.picker-container .rdrDay:not(.rdrDayPassive) .rdrInRange ~ .rdrDayNumber span{
  color: rgba(255, 255, 255, 0.85) !important;
}
.rdrDayDisabled {
  background-color: var(--color-transparent) !important;
}

.rdrDayDisabled .rdrDayNumber span {
  color: var(--color-text-80-dark) !important;
}

.rdrDayPassive .rdrDayNumber span {
  color: var(--color-text-70-dark) !important;
}

.rdrNextPrevButton {
  background: var(--color-transparent) !important;
}

.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  font-weight: 400 !important;
}

.rdrStaticRangeLabel {
  padding: 6px 20px !important;
}

.date-range-picker-analytics {
  position: absolute;
  right: -16px;
  top: 54px;
}

.picker-container{
  position: relative;
}

@media only screen and(max-width: 600px) and (min-width: 350px) {
  .date-range-picker {
    display: flex;
    position: absolute;
    top: 48px;
    right: -36px;
    width: 30px;
    overflow-x: scroll;
    min-width: 339px;
    width: 100%;
  }
}