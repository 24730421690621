.options-wrapper {
	position: relative;
}
.options-box {
	position: absolute;
	right: 0%;
	margin-top: 0px;
	margin-right: 22px;
	min-width: 168px;
	background: var(--color-bg-100-light);
	top: 100%;
	opacity: 22;
	padding: 12px 0;
	border-radius: 8px;
	min-height: 180;
	box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
	display: flex;
	flex-direction: column;
	z-index: 1;
}
.menu-item {
	z-index: 1;
	font-weight: 500;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-text-70-light);
	width: 100%;
	border: none;
	background-color: unset;
	padding: 4px 8px;
	display: flex;
	align-items: center;
	gap: 4px;
	height: 40px;
	min-width: 170px;
}
.space {
	margin-right: 8px;
	font-weight: 500;
	font-size: 18px;
	margin-left: 8px;
	line-height: 20px;
	color: var(--color-text-70-light);
}
.menu-item:hover {
	background-color: var(--color-bg-90-light);
	border-radius: 2px;
	transition: all 0.3s;
	cursor: pointer;
}
