.VerificationTimeInterval {
	min-width: 720px;
	min-height: 480px;
	display: flex;
	flex-direction: column;
	.Modal__wrapper {
		padding: unset;
	}
}
.VerificationTimeInterval__header {
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	border-bottom: 1px solid var(--color-border-light-100, #dfe3ec);
}
.VerificationTimeInterval__title {
	font-size: var(--font-size-4);
	font-weight: 600;
	line-height: 24px;
	color: #363a45;
}
.VerificationTimeInterval__sub-title {
	font-size: var(--font-size-2);
	font-weight: 500;
	line-height: 16px;
	color: var(--Color-Text-Light-60, #7c8398);
}
.VerificationTimeInterval__footer {
	padding: 16px 24px;
	border-top: 1px solid var(--color-border-light-100, #dfe3ec);
	display: flex;
	justify-content: flex-end;
	align-items: center;
	gap: 8px;
}
.VerificationTimeInterval__body {
	min-height: 200px;
	padding: 16px 24px;
	display: flex;
	flex-direction: column;
	gap: 8px;
}
.VerificationTimeInterval__detailsWrapper {
	display: grid;
	border-bottom: 1px solid #d9d9d9;
	padding-bottom: 8px;
	grid-template-areas:
		'companyName companyName companyEmail editBtn'
}
.VerificationTimeInterval__companyName {
	font-size: var(--font-size-4);
	font-weight: 500;
	line-height: 24px;
	grid-area: companyName;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 580px;
}
.VerificationTimeInterval__companyEmail {
	font-size: var(--font-size-3);
	font-weight: 400;
	line-height: 20px;
	color: #515767;
	grid-area: companyEmail;
	white-space: nowrap;
	overflow: hidden;
	text-overflow: ellipsis;
	max-width: 300px;
}
.VerificationTimeInterval__editBtn {
	color: var(--color-primary-light, #0051cc);
	font-size: var(--font-size-5);
	font-weight: 500;
	line-height: 24px;
	grid-area: editBtn;
	text-align: end;
	cursor: pointer;
}

.VerificationTimeInterval__verificationWrapper {
	display: flex;
	align-items: center;
	gap: 8px;
	.input {
		width: 96px;
		min-width: 96px;
	}
	.input__group {
		height: 52px;
	}
	.dropdown {
		width: 100px;
		min-width: 100px;
	}
	.dropdown__container--disabled {
		cursor: auto;
	}
	.css-olqui2-singleValue{
		color: var(--color-text-light-90);
	}
	.css-1fwwzb6-control{
		background: transparent;
	}
	.css-1hb7zxy-IndicatorsContainer , .css-1xc3v61-indicatorContainer {
		display: none;
	}
}
.VerificationTimeInterval__input {
	width: 96px;
	height: 52px;
	padding: 12px 16px;
	border-radius: 8px;
	border: 2px solid #0000000f;
	background: #fff;
	font-size: var(--font-size-4);
	font-weight: 500;
	line-height: 24px;
	color: #363a45;
}
.VerificationTimeInterval__verificationLabel {
	font-size: var(--font-size-4);
	font-weight: 600;
	line-height: 24px;
	white-space: nowrap;
	width: 100%;
}
.VerificationTimeInterval__editWrapper {
	width: 100%;
	height: 52px;
	padding: 8px 10px;
	display: flex;
	align-items: center;
	gap: 8px;
}
.VerificationTimeInterval__editTime {
	font-size: var(--font-size-4);
	font-weight: 500;
	line-height: 24px;
	color: #363a45;
	max-width: 130px;
	overflow-x: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
}
.VerificationTimeInterval__editDays {
	font-size: var(--font-size-4);
	font-weight: 500;
	line-height: 24px;
	color: #363a45;
	text-transform: capitalize;
}
