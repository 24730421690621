@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Poppins", sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 16px;
}
.loader-center {
  display: grid;
  place-items: center;
  height: 100vh;
}

.Embed-container{
  height: 100vh;
  width: 100%;
}

.Embed-container iframe {
  border: none;
}