.mobile-link {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	&__wrapper {
		max-width: 576px;
		display: flex;
		flex-direction: column;
		gap: 44px;
		background: var(--color-bg-100-light);
		border-radius: calc(var(--base-border-radius) * 0.5);
	}
	&__row {
		width: 100%;
		display: flex;
		flex-direction: column;
		align-items: center;
		gap: 8px;
	}
	&__title {
		font-weight: 600;
		font-size: 20px;
		line-height: 28px;
		color: #1d1e21;
	}
	&__description {
		font-weight: 400;
		font-size: 14px;
		line-height: 24px;
		color: var(--color-text-130-light);
		text-align: center;
		span {
			color: #363a45;
			font-weight: 500;
		}
	}
	&__resend {
		color: #0051cc !important;
		cursor: pointer;
		&_stop {
			cursor: not-allowed;
			opacity: 0.6;
		}
	}
	&__number {
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 8px;
		width: 56px;
		height: 56px;
		background: #f2f4f7;
		border: 1px solid var(--color-input-border-light);
		border-radius: 32px;
		font-style: normal;
		font-weight: 600;
		font-size: 24px;
		line-height: 32px;
		text-align: center;
		color: var(--color-bg-90-dark);
	}
	&__specification {
		font-style: normal;
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		text-align: center;
		color: var(--color-text-60-light);
	}
	&__mobile-number {
		color: var(--color-primary-dark);
	}
}
@media only screen and (max-width: 600px) and (min-width: 350px) {
	.mobile-link__wrapper {
		max-width: 346px;
		display: flex;
		flex-direction: column;
		gap: 16px;
		background: var(--color-bg-100-light);
		padding: calc(var(--base-padding) * 3) calc(var(--base-padding) * 2);
		border-radius: calc(var(--base-border-radius) * 0.5);
	}
}
