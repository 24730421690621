.wrapper {
	align-items: center;
	display: flex;
	justify-content: center;
	width: 100%;
}

.inner_wrapper {
	max-width: 576px;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 40px;
}

.inner {
	display: flex;
	flex-direction: column;
	gap: 16px;
}

.title {
	font-weight: 600;
	font-size: 24px;
	line-height: 32px;
	color: var(--color-text-100-light);
}

.sub_title {
	font-weight: 400;
	font-size: 14px;
	line-height: 20px;
	color: var(--color-text-60-light);
}
.lists {
	display: flex;
	flex-direction: column;
	gap: 16px;
	flex: 1;
}

.list_item {
	display: flex;
	gap: 8px;
	align-items: center;
	background: rgba(51, 184, 122, 0.06);
	border-radius: 8px;
	padding: 16px;
	cursor: pointer;
	color: var(--color-green);
	&:hover {
		background: rgba(245, 175, 69, 0.06);
		color: var(--color-orange);
	}
}

.list_item_disabled {
	cursor: not-allowed;
}

.left_icon {
	background: rgba(51, 184, 122, 0.12);
	border-radius: 24px;
	width: 40px;
	height: 40px;
	display: grid;
	place-items: center;
	font-size: 24px;
}

.right_icon {
	font-size: 24px;
}

.method_title {
	text-align: initial;
	font-weight: 600;
	font-size: 16px;
	line-height: 24px;
	color: var(--color-text-80-light);
	flex: 1;
}

.web-auth-register-button {
	background: var(--color-primary-light) !important;
}
