.analytics-transactions-container {
	display: flex;
	flex-flow: column;
	gap: 16px;
	overflow-y: auto;
	height: calc(90vh - 174px);
	padding: 24px;
	background: #f4f4f6;

	.analytics-transactions-filter {
		align-self: flex-end;
		position: relative;
	}
}
