.LatterAvater {
	width: 40px;
	height: 40px;
	border-radius: 50%;
	background: hsl(258, 58%, 42%);
	font-size: 14px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
}
