.session-download-btn {
	position: fixed;
	top: 24px;
	right: 60px;
	z-index: 3;
	display: flex;
	align-items: center;
	gap: 20px;

	&_toggle {
		display: flex;
		align-items: center;
		gap: 16px;
	}
	&_text {
		font-weight: 500;
		font-size: 16px;
		color: var(--color-header-label-dark);
	}
}

.kycDetails-contain {
	margin-top: 2px;
	min-width: 640px;
}

.kycDetails--header--sticky {
	/* z-index: 2; */
	width: 100%;
}

.session-column .activity-sticky {
	height: 85%;
	margin-bottom: 0;
	overflow-y: auto;
	position: -webkit-sticky;
	position: sticky;
	top: 120px;
	z-index: 1;
}

.kycDetails-header {
	margin-left: 4px;
}
.session-info {
	margin-top: 0px;
}

.kycDetail-userDetails {
	img {
		width: 100%;
		height: 100%;
	}
}

.session-details-loader-container {
	height: 400px;
}

.complex-session-view {
	overflow: auto;
	width: 100%;
	height: 100%;
	display: flex;
	align-items: center;
}
