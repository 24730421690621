.conditional-onboarding-details {
	width: 100vw !important;
	max-width: 100vw !important;

	.Modal__wrapper {
		height: calc(100vh - 40px);
	}

	&__title-wrapper {
		border-bottom: 1px solid var(--color-white-border);
		margin: 0 -24px;
		padding: 0 0 16px 24px;
	}

	&__title {
		color: #2f323d;
		font-weight: 600;
		font-size: 16px;
		line-height: 24px;
	}
	&__sub-title {
		color: var(--color-text-60-light);
		font-weight: 500;
		font-size: 12px;
		line-height: 16px;
		padding-top: 4px;
	}
}

.conditional-flow-steps {
	min-width: 400px;
	min-height: 600px;
	display: flex;
	justify-content: center;
	align-items: center;
}
