.data-grid {
	width: 100%;
	height: 100%;
	.cell {
		// max-height: 30px !important;
		// min-height: 30px !important;
		width: 100%;
		padding: 4px 16px !important;
		font-size: 14px;
		input {
			height: 100% !important;
			width: 100%;
			&:focus {
				border: none !important;
				outline: none !important;
			}
		}
	}
	.br-none {
		border: none;
	}
	.title {
		.value-viewer {
			color: var(--color-text-80-light) !important;
			font-size: var(--font-size-3);
			font-weight: 600;
		}
	}
}
