.business-user-list-container {
	.header {
		border-bottom: 1px solid rgba(51, 87, 184, 0.12);
	}
}

.business-user-list--container {
	padding: calc(var(--base-padding) * 1.5) 0px;
	width: 100%;
	height: calc(90vh - 174px);

	.status-wrapper {
		display: flex;
		align-items: center;
		gap: 12px;
	}

	.paid,
	.trial,
	.rejected,
	.completed,
	.approved,
	.pending,
	.billing {
		width: 6px;
		height: 6px;
		border-radius: 50%;
	}

	.paid {
		background-color: var(--color-green);
	}

	.trial {
		background-color: var(--color-accent-dark);
	}

	.rejected {
		background-color: var(--color-rejected);
	}

	.completed {
		background-color: var(--color-success);
	}

	.approved {
		background-color: var(--color-success);
	}

	.billing {
		background-color: var(--color-accent-dark);
	}

	.pending {
		background-color: var(--color-accent-dark);
	}
}

.kycDetails-contain {
	margin-top: 2px;
	min-width: 640px;
}

.session-column .activity-sticky {
	height: 85%;
	margin-bottom: 0;
	overflow-y: auto;
	position: -webkit-sticky;
	position: sticky;
	top: 120px;
	z-index: 1;
}

.kycDetails-header {
	margin-left: 4px;
}
.session-info {
	margin-top: 0px;
}

.kycDetail-userDetails {
	img {
		width: 100%;
		height: 100%;
	}
}

.user-list-modal {
	position: fixed;
	left: 0;
	top: 0;
	right: 0;
	bottom: 0;
	background: fade-in($color: transparent, $amount: 0.2);
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;

	.modal-content {
		max-width: 480px;
		background-color: var(--color-bg-100-light);
		border-radius: calc(var(--base-border-radius) * 0.75);
		text-align: center;

		.modal-title {
			margin: 12px;
			font-weight: 700;
			font-size: var(--font-size-4);
			line-height: var(--line-height-4);
		}

		.modal-footer {
			display: flex;
			gap: calc(var(--base-margin));
			justify-content: flex-end;
			padding: 8px 10px;
			border-top: 1px solid var(--color-white-border);
			.button-label-txt {
				font-size: 14px;
			}
		}
	}
}

.modal-des {
	font-style: normal;
	font-weight: 500;
	font-size: var(--font-size-3);
	line-height: var(--line-height-3);
	display: flex;
	align-items: center;
	justify-content: center;
	padding: calc(var(--base-padding) * 1.5) calc(var(--base-padding) * 2);
	.modal-delete-msg {
		text-transform: lowercase;
	}
}

.confirmation-header {
	height: 160px;
	border-bottom: 1px solid var(--color-white-border);
	padding: var(--base-padding) calc(var(--base-padding) * 0.5);
	background-color: var(--color-bg-modal);
	border-top-left-radius: calc(var(--base-padding) * 0.75);
	border-top-right-radius: calc(var(--base-padding) * 0.75);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.confirmation-image {
	width: 96px;
	height: 96px;
}

.lock-unlock--container {
	display: flex;
	align-items: center;
	gap: 20px;
	.action-icon-wrapper {
		font-size: 20px;
		cursor: pointer;

		.ri-delete-bißn-line {
			color: red;
		}

		&:hover {
			color: var(--color-primary-light);
			font-weight: 600;
		}
	}
}

.disabled_btn {
	cursor: not-allowed !important;
}
