#dropdown__container {
  display: flex;
  gap: 4px;
  flex-direction: column;
.dropdown {
    width: 100%;
}
  .option {
    display: flex;
    gap: calc(var(--base-margin) * 0.25);
    .option-name {
      color: var(--color-text-100-dark);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .option-subname {
      color: var(--color-text-100-dark);
    }
  }
}

.dropdown__container--disabled{
  cursor: not-allowed;
}
