.CreateNewRole--container {
	width: 750px;
	height: 80vh;
	display: flex;
	flex-direction: column;
	padding: 8px 4px;
	gap: 20px;
	&__body {
		display: flex;
		flex-direction: column;
		gap: 20px;
		padding: 8px;
		&--text {
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-start;
			gap: 4px;
			&__header {
				color: var(--color-input-border-dark);
				text-align: center;
				font-size: 16px;
				font-weight: 600;
			}
			&__desc {
				color: var(--color-text-130-light);
				text-align: center;
				font-size: 14px;
				font-weight: 400;
			}
		}
	}
}
