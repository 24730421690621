@import 'variables/colors.scss';
@import 'variables/font-size.scss';
@import 'variables/padding.scss';
@import 'variables/border-radius.scss';
@import 'variables/margin.scss';

.dashboard-container {
	max-height: 100vh;
	overflow: hidden;
	background-color: #f4f4f6;
}

.dashboard-body {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: auto 1fr;
	// height: 100vh;
	// overflow: hidden;
	&__loader {
		height: 100vh;
	}
}

// kyc loader
.page-loading {
	width: 100%;
}

// credential css
.action {
	font-size: var(--font-size-4);
	cursor: pointer;
	i {
		margin: 4px;
	}
}
.delete {
	color: var(--color-delete);
}

.table-view {
	width: 100%;
	padding: 40px;
	display: flex;
	flex-direction: column;
}
.table-header {
	display: flex;
	justify-content: space-between;
}
.text {
	padding-left: var(--zero-padding);
}
.add-btn {
	padding-right: var(--base-padding);
	padding-bottom: var(--base-padding);

	height: 44px;
	width: 71px;
}
.copy {
	cursor: pointer;
}

.dashboard-main-body {
	width: 100%;
	overflow-x: auto;
}
