.audit-logs {
	height: 100%;
	width: 100%;
	&__lists {
		display: flex;
		flex-direction: column;
		gap: 24px;
		padding: 24px;
		overflow: auto;
		height: 100%;
		max-height: calc(100vh - 64px);
		&__item {
			display: flex;
			align-items: center;
			width: 100%;
			padding: 16px;
			border-radius: 8px;
			border: none;
			outline: none;
			background-color: var(--color-bg-100-light);
			gap: 16px;
			&:hover {
				box-shadow: 0px 0px 8px 0px #0000003d;
			}
			&__icon {
				width: 44px;
				height: 44px;
				display: grid;
				place-items: center;
				border-radius: 32px;
				background: #e89c2c0f;
				i {
					font-size: 22px;
				}
			}
			&__text-container {
				display: grid;
				gap: 4px;
				&__text {
					display: flex;
					gap: 8px;
					font-size: 14px;
					font-weight: 500;
					line-height: 20px;
					color: #515767;
					a {
						text-decoration: none;
					  }
				}
				&__date {
					font-size: 12px;
					font-weight: 400;
					line-height: 16px;
					color: #7c8398;
				}
			}
			&__completed {
				padding: 2px 12px;
				color: var(--color-primary-light);
				font-size: 12px;
				font-weight: 500;
				border-radius: 32px;
				background: #3C65D60F;
				cursor: pointer;
				a{
					color: var(--color-primary-light);
				}
			}
			&__rejected{
				padding: 2px 12px;
				font-size: 12px;
				font-weight: 500;
				border-radius: 32px;
				background: #EF43430F;
				cursor: pointer;
				a{
					color: var(--color-failed);
				}
			}
		}
	}
}

.audit-log-filter-container {
	display: none;
	justify-content: end;
	min-width: 900px;
}

.audit-log-inner-filter-container {
	display: flex;
	gap: 20px;
	align-items: center;
	font-weight: bold;
}

.audit-log-filter-inner-container {
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 12px;
}

.audit-log-filter-by-user {
	display: flex;
	gap: 4px;
	align-items: center;
	font-size: 12px;
}

.arrow-size {
	font-size: 20px;
}

.action {
	&_LoggedIn,
	&_Invited,
	&_Created,
	&_roleUpdate {
		color: #7935de;
	}
	&_LoggedOut,
	&_priceUpdate,
	&_sessionView {
		color: #e88a2c;
	}
	&_Locked,
	&_Unlocked,
	&_searchBusiness,
	&_sessionUpdate {
		color: #2269d3;
	}

	&_Removed,
	&_Rejected,
	&_roleDelete,
	&_Blocked,
	&_rejected,
	&_deleted {
		color: var(--color-dark-red);
	}
	&_Approved,
	&_roleCreate,
	&_Unblocked,
	&_completed {
		color: var(--color-dark-green);
	}
}
