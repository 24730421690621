.revenue-details-overview-container {
	display: flex;
	flex-wrap: wrap;
	gap: 16px;

	.revenue-details-overview {
		display: flex;
		flex: 1;
		background-color: white;
		padding: 12px;
		border-radius: 8px;
		box-sizing: border-box;
		border: solid 1px #dfe3ec;
		gap: 12px;
		align-items: center;
		min-width: 220px;
		&:hover {
			transition: all 0.2s ease-in-out;
			box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
		}
		img {
			height: 40px;
			width: 40px;
			border-radius: 24px;
			padding: 8px;
			gap: 8px;
		}

		img.dollar {
			background-color: #2269d3;
		}

		img.pay {
			background-color: #e89c2c;
		}

		img.month {
			background-color: #7935de;
		}

		img.business {
			background-color: #389f75;
		}

		img.session {
			background-color: #ce73aa !important;
		}

		img.onboarding {
			background-color: #de3535 !important;
		}

		.revenue-details-profile-header {
			display: flex;
			justify-content: space-between;
			align-items: center;
			gap: 4px;
			padding: 4px 0;
		}

		.revenue-details-overview-text {
			display: flex;
			flex-direction: column;
			flex: 1;

			p {
				margin-bottom: 0;
			}

			.revenue-details-profit {
				font-size: 20px;
				font-weight: 600;
				color: #1d1e21;
				line-height: 32px;
			}

			p.pay:before {
				content: '$';
			}

			p.month:before {
				content: '$';
			}

			p.dollar::before {
				content: '$';
			}

			.revenue-details-margin {
				font-weight: 500;
				font-size: 12px;
				color: #389f75;
				line-height: 20px;
			}

			.revenue-margin-positive {
				color: var(--color-green);
			}

			.revenue-margin-negative {
				color: var(--color-danger-dark);
			}

			.revenue-details-title {
				font-weight: 500;
				font-size: 14px;
				color: #7c8398;
				line-height: 20px;
			}
		}
	}
}
