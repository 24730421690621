.UserRoleSkeletonLoader {
	display: flex;
	flex-direction: row;
	gap: 16px;
	padding: 16px 24px;
	flex-flow: wrap;
	width: 100%;

	.UserRoleSkeletonLoader--container {
		width: calc((100% - 32px) / 3);
		height: 250px;
		@media only screen and (max-width: 900px) {
			width: calc((100% - 16px) / 2);
		}
		@media only screen and (max-width: 650px) {
			width: 100%;
		}

		&__header {
			display: flex;
			justify-content: space-between;
			width: 100%;
			padding: 20px;
			height: 100%;
			flex-direction: column;
			gap: 24px;
			border-radius: 6px;
			min-height: 250px;
			border: 1px solid var(--color-label-100-light);
			background-color: var(--color-bg-100-light);
			&--label {
				display: flex;
				justify-content: space-between;
				gap: 8px;
				width: 100%;
			}
		}
		&__permissionTag {
			display: flex;
			gap: 8px;
			width: 100%;
		}
		&__text {
			display: flex;
			flex-direction: column;
			gap: 8px;
			width: 100%;
		}
		&__inviteUser {
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
		}
		&__profile {
			display: flex;
			width: 100%;
			position: relative;
			.overlap-image-two {
				position: absolute;
				left: 20px;
			}
		}

		&__labelContainer {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}
	}
}

// ASSINED USER

.AssinedUserSkeletonLoader--container {
	width: 100%;
	height: 100%;
	display: flex;
	width: 100%;
	gap: 8px;
	flex-direction: column;
	&__header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		&__title {
			color: var(--color-text-light-90);
			font-size: 16px;
			font-weight: 600;
			&--usersCount {
				color: var(--color-text-light-60);
				font-size: 16px;
				font-weight: 500;
			}
		}
		&--inviteUser {
			display: flex;
			padding: 8px 12px;
			justify-content: center;
			align-items: center;
			gap: 4px;
			border-radius: 4px;
			border: 0px solid var(--color-primary-light);
			color: var(--color-primary-light);
			font-size: 14px;
			font-weight: 500;
			cursor: pointer;
		}
	}
	&__userList {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
		gap: 16px;
		flex: 1 0 0;
		align-self: stretch;
		&--data {
			display: flex;
			align-items: center;
			gap: 12px;
			align-self: stretch;
			position: relative;
			.ComplainceAgent--popup {
				display: flex;
				width: 168px;
				padding: 8px 0px;
				flex-direction: column;
				align-items: flex-start;
				border-radius: 4px;
				background-color: var(--color-bg-100-light);
				box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.12);
				position: absolute;
				top: 30px;
				left: 105px;
				&__edit {
					display: flex;
					width: 168px;
					padding: 8px 16px;
					align-items: center;
					gap: 8px;
					&--icon {
						display: flex;
						width: 20px;
						height: 20px;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
					}
					&--label {
						flex: 1 0 0;
						color: var(--color-text-130-light);
						font-size: 14px;
						font-weight: 500;
						line-height: 20px;
						cursor: pointer;
					}
				}
				&__edit:hover {
					background-color: var(--color-bg-90-light);
					border-radius: 2px;
					transition: all 0.3s;
					cursor: pointer;
				}
				&__delete {
					display: flex;
					width: 168px;
					padding: 8px 16px;
					align-items: center;
					gap: 8px;
					&--icon {
						display: flex;
						width: 20px;
						height: 20px;
						justify-content: center;
						align-items: center;
						flex-shrink: 0;
					}
					&--label {
						flex: 1 0 0;
						color: var(--color-text-130-light);
						font-size: 14px;
						font-weight: 500;
						cursor: pointer;
					}
				}
				&__delete:hover {
					background-color: var(--color-bg-90-light);
					border-radius: 2px;
					transition: all 0.3s;
					cursor: pointer;
				}
			}
			&__label {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;
				flex: 1 0 0;
				&--name {
					color: var(--color-text-light-90);
					font-size: 16px;
					font-weight: 500;
				}
				&--account {
					color: var(--color-text-light-60);
					font-size: 14px;
					font-weight: 500;
				}
			}
			&__icon {
				display: flex;
				width: 24px;
				height: 24px;
				justify-content: center;
				align-items: center;
				font-size: 24px;
				cursor: pointer;
			}
		}
	}
}
