.InviteUserFormModal {
	padding: unset;
	padding: 16px 0 24px 0px;
	width: calc(100vw - 500px) !important;
	min-width: 500px;
	&--body {
		width: 100%;
		height: 100%;
		display: flex;
		flex-direction: column;
		padding: 24px;
		gap: 16px;
		overflow: scroll;
	}
	&--row-2 {
		display: flex;
		gap: 8px;
		flex-direction: column;
		@media screen and (min-width: 768px) {
			flex-direction: row;
		}
	}
	&--header {
		border-bottom: 1px solid #eee;
		padding-inline: 24px;
		h2 {
			font-size: 20px;
			line-height: 28px;
		}
	}
	&--buttons {
		border-top: 1px solid #eee;
		padding: 16px 16px 0 16px;
		display: flex;
		justify-content: flex-end;
		gap: 10px;
	}
}
