.session_container {
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 24px;
	width: 50px;
	gap: 4px;
	width: 100%;
}
.session-loader-light {
	// width: 100%;
	height: 20px;
	border-radius: 50px;
	display: block;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#adb0b8;
	background-repeat: repeat-y;
	background-size: 20px 50px;
	background-position: 0 0;
	animation: shine 2s infinite;
}

#parent-session-details {
	display: flex;
	flex-direction: column;
	padding: 12px;
	gap: 4px;
}

@keyframes shine {
	to {
		background-position: 100% 0, /* move highlight to right */ 0 0;
	}
	100% {
		background-position: 100% 0;
	}
}

.sessionactivitiy-log--loader {
	display: flex;
	gap: 10px;
	align-items: center;
}
.session-information--loader {
	display: flex;
	flex-wrap: wrap;
	gap: 10px;
	justify-content: center;
	&__subcontainer {
		padding: 8px;
		flex: 0 0 calc(50% - 16px);
		box-sizing: border-box;
	}
}

.sessioninformation {
	display: flex;
	justify-content: center;
	align-items: center;
	height: 24px;
	width: 50px;
}

.loaderlight {
	width: 100%;
	height: 20px;
	border-radius: 50px;
	display: block;
	background: linear-gradient(
			to right,
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0.2) 50%,
			rgba(255, 255, 255, 0) 80%
		),
		#adb0b8;
	background-repeat: repeat-y;
	background-size: 20px 50px;
	background-position: 0 0;
	animation: shine 2s infinite;
}

.allactivity-details--container {
	display: flex;
	gap: 12px;
	align-items: center;
	padding: 8px;
}

#allactivity-details {
	display: flex;
	flex-direction: column;
	gap: 8px;
}
