.onboarding-flow-analytics {
	display: flex;
	flex-direction: column;
	gap: 16px;
	&_container {
		display: flex;
		flex-wrap: wrap;
		gap: 16px;
		width: 100%;
		.chart-container {
			padding: 24px 16px 16px;
			background: var(--color-bg-100-light);
			border-radius: 8px;
			width: calc((100vw - 236px) / 3);
			&:hover {
				transition: all 0.2s ease-in-out;
				box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
			}
			@media (max-width: 870px) {
				width: calc((100vw - 220px) / 2);
			}
			@media (max-width: 648px) {
				width: 100%;
			}
		}
	}
}
