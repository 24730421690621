.PipelineBody--iframe-loader{
    margin: 0;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.api-dashboard--iframe{
    border: none;
}
