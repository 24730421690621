.ApprovalModal {
	&__session-details {
		height: 100%;
		max-height: 80vh;
		overflow: auto;
		padding: 0px 16px;
		.kycDetails--header,
		.session-column {
			flex-direction: column !important;
		}
		.session-column .session-col-detail {
			width: 100% !important;
		}
	}
	&__loader-wrapper,
	&__no-data {
		padding-top: 150px;
	}
	&__btn_wrapper {
		display: flex;
		align-items: center;
		gap: var(--base-margin);
		justify-content: flex-end;
		border-top: 1px solid var(--color-white-border);
		padding: 8px 24px;
	}
	&--container{
		.NoPhysicalDocUploaded {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			min-height: 480px;
			gap: 16px;
			padding: 16px 24px;
		
		  &--header {
			color: var(--color-black);
			font-size: 20px;
			font-weight: 600;
		  }
		  &--subHeader {
			color: var(--color-text-70-light);
			font-size: 14px;
			font-weight: 400;
			text-align: center;
		  }
		  &--btn {
			display: flex;
			justify-content: center;
			align-items: center;
			gap: 8px;
			.btn-danger {
			  background: var(--color-error);
			  color: var(--color-bg-90-light);
			  justify-content: center;
			  min-width: 120px;
			  padding: 16px 24px;
			  font-size: 14px;
			  font-weight: 500;
			  cursor: pointer;
			}
			.btn-success {
			  background: var(--color-green) !important;
			  color: var(--color-bg-90-light) !important;
			  padding: 16px 24px;
			  min-width: 120px;
			  justify-content: center;
			  font-size: 14px;
			  font-weight: 500;
			  cursor: pointer;
			}
		  }
		}
	}

}
